import React, { useState, useEffect } from "react";
import TestHeading from "../components/Common/TestHeading";
import ROPLogo from "../assets/images/ROP_Logo.svg";
import { ROP_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_QCard_Div } from "./Style";
import ReOrderParagraphs from "../components/Reading/ReOrderParagraphs";
import { FlexDiv } from "../assets/styles/style";
import SidePannel from "../components/Common/SidePannel";
import { useAuth } from "../authentication/Auth";
import { useNavigate } from "react-router-dom";
import { AiScorePopupReadingMCMAData } from "../components/Reading/data";
import AiScorePopupReadingROP from "../components/Reading/AiScorePopupReadingROP";
import ReadingAnswerBoxROP from "../components/Reading/ReadingAnswerBoxROP";
import { SidePannelBackdrop } from "../components/Common/Style";
import LoadingModal from "../components/Common/LoadingModal";
import { Base_URL } from "../Client/apiURL";

const questionname = "Re-order Paragraphs";

const ReadingROP = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const [questionsData, setQuestionsData] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionId, setQuestionID] = useState();
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [triggerReset, setTriggerReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userOrder, setUserOrder] = useState([]);
  const [submissionResult, setSubmissionResult] = useState({
    userIndexes: [],
    correctIndexes: [],
    score: 0,
  });
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [isPracticed, setIsPracticed] = useState("all");
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    // console.log("Current Index:", currentQuestionIndex);
    // console.log("Total questions on this page:", testQuestions.response?.length);
    // console.log("Current page:", page);
    // console.log("Total pages:", totalPages);

    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  useEffect(() => {
    if (
      questionsData &&
      questionsData.response.OptionNames &&
      questionsData.response.AnswerNames
    ) {
      const correct = findMatchingLetters(
        questionsData.response.OptionNames,
        questionsData.response.AnswerNames
      );
    }
  }, [questionsData]);

  function canSubmit() {
    return false;
  }

  const calculateScoreAndIndexes = () => {
    if (!questionsData?.response?.AnswerNames || userOrder.length === 0)
      return { userIndexes: [], correctIndexes: [], score: 0 };

    // Map the correct answers to their indexes in the options array
    const correctOrderIndexes = questionsData.response.AnswerNames.map(
      (answer) => questionsData.response.OptionNames.indexOf(answer)
    );

    let score = 0;
    // Check pairs rather than individual indexes
    for (let i = 1; i < userOrder.length; i++) {
      // Check if both current and previous elements match the correct order
      if (
        userOrder[i] === correctOrderIndexes[i] &&
        userOrder[i - 1] === correctOrderIndexes[i - 1]
      ) {
        score += 1;
      }
    }

    return {
      userIndexes: userOrder,
      correctIndexes: correctOrderIndexes,
      score,
    };
  };

  // const calculateScoreAndIndexes = () => {
  //   if (!questionsData?.response?.AnswerNames || userOrder.length === 0)
  //     return { userIndexes: [], correctIndexes: [], score: 0 };

  //   const correctOrderIndexes = questionsData.response.AnswerNames.map(
  //     (answer) => questionsData.response.OptionNames.indexOf(answer)
  //   );

  //   let score = 0;
  //   userOrder.forEach((index, i) => {
  //     if (index === correctOrderIndexes[i]) score += 1;
  //   });

  //   return {
  //     userIndexes: userOrder,
  //     correctIndexes: correctOrderIndexes,
  //     score,
  //   };
  // };

  const findMatchingLetters = (bigArray, smallArray) => {
    const matchingLetters = [];

    for (let i = 0; i < bigArray.length; i++) {
      if (smallArray.includes(bigArray[i])) {
        matchingLetters.push(String.fromCharCode(65 + i));
      }
    }
    return matchingLetters;
  };

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  useEffect(() => {
    const fetchData = async () => {
      const encodedQuestionName = encodeURIComponent(questionname);
      const searchQueryParam = searchTerm ? `&search_name=${encodeURIComponent(searchTerm)}` : "";
      const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
      let sortDescParam = highFrequency ? "" : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
      let isPracticedParam = isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
      let isPredictionParam = filterPrediction ? `&prediction=${filterPrediction}` : `&prediction=${false}`;
      let isPteCoreParam = false;
     
      const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

      try {
        const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 501) {
          logout();
          navigate("/login");
          return;
        }

        setTestQuestions(data);
        if (data.response && data.response.length > 0) {
          setQuestionID(data.response[0].QuestionId);
          setTestQuestionTableId(data.response[0].TestQuestionTableId);
        }

        const totalQuestions = data.totalQuestions || 1;
        const questionsPerPage = 8;
        const calculatedTotalPages = Math.ceil(
          totalQuestions / questionsPerPage
        );
        setTotalPages(calculatedTotalPages);
      } catch (error) {
        console.error("Failed to fetch test questions:", error.message);
      }
    };

    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    wantToSortDesc,
    highFrequency,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        submissionResult: submissionResult,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained: submissionResult.score,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          console.log(data.message);
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit && !scoreDataSubmitted && elapsedTime;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [isScoreDataReadyForSubmit, scoreDataSubmitted, elapsedTime]);

  const resetState = () => {
    setSelectedAnswers([]);
    setShowAnswer(false);
    setScorecardOpen(false);
    setIsSubmitted(false);
    setElapsedTime(0);
    setTriggerReset((prevState) => !prevState);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setShouldOpenScorecard(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  function handleSubmission() {
    setIsSubmitted(true);
    const result = calculateScoreAndIndexes();
    setSubmissionResult(result);
    setIsScoreDataReadyForSubmit(true);
    setScorecardOpen(true);
  }

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      {scorecardOpen && (
        <FlexDiv
          style={{
            position: "fixed",
            top: " 50%",
            left: " 50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1002",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            width: "100vw",
            height: "100vh",
          }}
        >
          <AiScorePopupReadingROP
            EnableSkills={[
              {
                component: "Pair",
                // score: myAttemptedQuestionsScore
                //   ? `${
                //       JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                //         .submissionResult.score
                //     }/4`
                //   : `${submissionResult.score}/4`,
                score: myAttemptedQuestionsScore
                  ? `${
                      JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                        .submissionResult.score
                    }/${questionsData?.response?.AnswerNames.length - 1}`
                  : `${submissionResult.score}/${
                      questionsData?.response?.AnswerNames.length - 1
                    }`,
                suggestion: "Excellent!",
              },
            ]}
            SmallScoreCard={[AiScorePopupReadingMCMAData[0].SmallScoreCard[0]]}
            answerIsAList={false}
            submissionResult={
              myAttemptedQuestionsScore
                ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                    .submissionResult
                : submissionResult
            }
            elapsedTime={
              myAttemptedQuestionsScore
                ? myAttemptedQuestionsScore.TimeTaken
                : elapsedTime
            }
            close={setScorecardOpen}
            totalScore={questionsData?.response?.AnswerNames.length - 1}
          />
        </FlexDiv>
      )}
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        setTestQuestions={setTestQuestions}
        heading={"Re-order Paragraphs"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setTriggerReset}
        logo={ROPLogo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={ROPLogo}
            heading={"Re-order Paragraphs"}
            subheading={ROP_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            remainTime={false}
            onTestSubmit={handleSubmission}
            isSubmitted={isSubmitted}
            setElapsedTime={setElapsedTime}
            triggerReset={triggerReset}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            dictionaryArrayText={questionsData?.response?.OptionNames}
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />
          {questionsData && questionsData.response && (
            <SWT_QCard_Div>
              <ReOrderParagraphs
                key={`re-order-paragraph-${triggerReset}`}
                OptionNames={questionsData?.response?.OptionNames}
                setUserOrder={setUserOrder}
              />
            </SWT_QCard_Div>
          )}
          <ButtonList
            onSubmit={() => {
              handleSubmission();
            }}
            onRedo={() => handleRedo()}
            onAnswer={() => setShowAnswer(!showAnswer)}
            canSubmit={canSubmit}
            isLoading={isLoading}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showAnswer && questionsData?.response?.AnswerNames && (
            <ReadingAnswerBoxROP
              answer={calculateScoreAndIndexes()
                .correctIndexes.map((index) => index + 1)
                .join(", ")}
              addIndex={false}
            />
          )}
          <CommunityScore
            formateScore={false}
            bg="#AD826E"
            ai_score={false}
            ScoreLetter="R"
            totalScore={questionsData?.response?.AnswerNames.length - 1}
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default ReadingROP;
