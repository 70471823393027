import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import {
  SideCardContainerPH,
  // PHColumnsContainer,
  ColumnsText,
  // PHContentContainer,
  PHBasicText,
  PHPaymentStatusText,
  PHAmountText,
} from "./style";

const StyledTableContainer = styled(TableContainer)`
  &.MuiPaper-root {
    border-radius: 5px;
    box-shadow: none;
  }
  height: 682px;
  min-height: 682px;
  width: 100%;
`;

const PHColumnsContainer = styled(TableHead)`
  background: #996cfe;
`;

const PHContentContainer = styled(TableRow)`
  background: var(--White-Theme-Gray---0, #fff);
`;

const paymentData = [
  {
    id: "#1234567",
    date: "23/04/2023",
    items: "Basic Plan",
    status: "Pending",
    amount: "$612.123",
  },
  {
    id: "#1234568",
    date: "26/04/2023",
    items: "Mock Test",
    status: "Paid",
    amount: "$612.123",
  },
];

const PaymentHistory = () => {
  return (
    <StyledTableContainer component={Paper}>
      <Table>
        <PHColumnsContainer>
          <TableRow>
            <TableCell>
              <ColumnsText>Transaction ID</ColumnsText>
            </TableCell>
            <TableCell>
              <ColumnsText>Transaction Date</ColumnsText>
            </TableCell>
            <TableCell>
              <ColumnsText>Items</ColumnsText>
            </TableCell>
            <TableCell>
              <ColumnsText>Payment Status</ColumnsText>
            </TableCell>
            <TableCell>
              <ColumnsText>Amount</ColumnsText>
            </TableCell>
          </TableRow>
        </PHColumnsContainer>
        <TableBody>
          {paymentData.map((payment, index) => (
            <PHContentContainer key={index}>
              <TableCell>
                <PHBasicText>{payment.id}</PHBasicText>
              </TableCell>
              <TableCell>
                <PHBasicText>{payment.date}</PHBasicText>
              </TableCell>
              <TableCell>
                <PHBasicText>{payment.items}</PHBasicText>
              </TableCell>
              <TableCell align="center">
                <PHPaymentStatusText status={payment.status}>
                  {payment.status}
                </PHPaymentStatusText>
              </TableCell>
              <TableCell align="center">
                <PHBasicText>{payment.amount}</PHBasicText>
              </TableCell>
            </PHContentContainer>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default PaymentHistory;

// import React from "react";
// import {
//   SideCardContainerPH,
//   PHColumnsContainer,
//   ColumnsText,
//   PHContentContainer,
//   PHBasicText,
//   PHPaymentStatusText,
//   PHAmountText,
// } from "./style";

// const PaymentHistory = () => {
//   const paymentData = [
//     {
//       id: "#1234567",
//       date: "23/04/2023",
//       items: "Basic Plan",
//       status: "Pending",
//       amount: "$612.123",
//     },
//     {
//       id: "#1234568",
//       date: "26/04/2023",
//       items: "Mock Test",
//       status: "Paid",
//       amount: "$612.123",
//     },
//   ];

//   return (
//     <SideCardContainerPH>
//       <PHColumnsContainer>
//         <ColumnsText>Transaction ID</ColumnsText>
//         <ColumnsText>Transaction Date</ColumnsText>
//         <ColumnsText>Items</ColumnsText>
//         <ColumnsText>Payment Status</ColumnsText>
//         <ColumnsText>Amount</ColumnsText>
//       </PHColumnsContainer>
//       {paymentData.map((payment, index) => (
//         <PHContentContainer key={index}>
//           <PHBasicText>{payment.id}</PHBasicText>
//           <PHBasicText>{payment.date}</PHBasicText>
//           <PHBasicText>{payment.items}</PHBasicText>
//           <PHPaymentStatusText>{payment.status}</PHPaymentStatusText>
//           <PHAmountText>{payment.amount}</PHAmountText>
//         </PHContentContainer>
//       ))}
//     </SideCardContainerPH>
//   );
// };

// export default PaymentHistory;
