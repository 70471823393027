// import React, { useEffect } from "react";
// import moment from "moment";
// import useTimer from "../../hooks/useTimer";

// export default function RemainingTime({
//   showRemainingTime,
//   setShowRemainingTime,
//   stopStopwatch,
//   Category,
//   addTime,
//   time,
//   step,
//   setStep,
//   InitStep,
// }) {
//   const { timeLeft, isActive, startTimer, stopTimer, resetTimer } = useTimer(
//     moment.duration(showRemainingTime, "minutes")
//   );

  // useEffect(() => {
  //   console.log("Timer started", showRemainingTime);
  //   if (Category !== "Listening") {
  //    startTimer();
  //   }
  //   // stopStopwatch();// so the bigger clock doesnt start
  // }, [step]);


//   useEffect(() => {
//     if(Category === "Listening" && InitStep === 4){
//       console.log("in initstep useefect");
//       startTimer(moment.duration(10,"minutes"));
//     }
//   }, [InitStep])

//   useEffect(() => {
//     if (step > 1 && isActive) {
//       console.log("stopped because of next");
//       // resetTimer(moment.duration(showRemainingTime, "minutes"));
//       setShowRemainingTime(null);
//     }
//   }, [step]);

//   useEffect(() => {
//     if (step > 1 && isActive) {
//       console.log("started because of next");
//       resetTimer(moment.duration(showRemainingTime, "minutes"));
//       startTimer();
//       // stopStopwatch(); // so the bigger clock doesnt start
//       // setShowRemainingTime(null);
//     }
//   }, [showRemainingTime]);

//   useEffect(() => {
//     if (timeLeft === 0) {
//       console.log("Writing Time is up");
//       // if (Category === "listening") {
//       //   addTime(moment.duration(time, "minutes"));
//       // } else {
//       //   addTime(moment.duration(time, "minutes") - timeLeft);
//       // }
//       stopTimer();
//       setShowRemainingTime(null);
//       setStep((prev) => prev + 1);
//     }
//   }, [timeLeft]);

//   return <div>Remaining Time : {moment.utc(timeLeft).format("mm:ss")}</div>;
// }

import React, { useEffect } from "react";
import moment from "moment";
import useTimer from "../../hooks/useTimer";

export default function RemainingTime({
  showRemainingTime,
  setShowRemainingTime,
  stopStopwatch,
  Category,
  addTime,
  time,
  step,
  setStep,
  InitStep,
  questions,
}) {
  const { timeLeft, isActive, startTimer, stopTimer, resetTimer } = useTimer(moment.duration(showRemainingTime, "minutes"));

  // useEffect(() => {
  //   if (questions.length > 0) {
  //     const currentSubCategory = questions[step - 1]?.SubCategory;
  
  //     // Reset timer for each new question with allocated time
  //     if (showRemainingTime) {
  //       stopTimer(); // Stop the previous timer if any
  //       resetTimer(moment.duration(showRemainingTime, "minutes")); // Reset to new allocated time
  
  //       // Check which subcategory is currently active
  //       if (
  //         currentSubCategory === "Summarize Written Text" || 
  //         currentSubCategory === "Summarize Spoken Text" ||
  //         currentSubCategory === "Write Essay" || 
  //         currentSubCategory === "Write Email"
  //       ) {
  //         startTimer(); // Start timer for Writing tasks (Summarize Written Text, Write Essay, Write Email)
  //       }
  //     }
  //   }
  // }, [step, showRemainingTime, questions]);
  
  
  useEffect(() => {
    stopTimer();
    resetTimer(moment.duration(showRemainingTime, "minutes"));
    console.log(`Rendering question for step: ${step}, category: ${Category} and Sub-Category: ${questions[step - 1]?.SubCategory}`);
    console.log("Timer reset for new question with", showRemainingTime, "minutes");
    if (Category !== "Listening" && Category !== "Speaking" && Category !== "Reading" ) {
      startTimer();
      console.log("Timer started for Writing", showRemainingTime, "minutes");
    }
  }, [step, showRemainingTime, Category]);

    useEffect(() => {
      if (Category === "Listening" && questions[step - 1]?.SubCategory === "Summarize Spoken Text" && InitStep === 4) {
        startTimer(moment.duration(10, "minutes"));
        console.log("Started 10-minute timer for Summarize Spoken Text");
      } else if (Category === "Listening" && questions[step - 1]?.SubCategory !== "Summarize Spoken Text" && InitStep === 4) {
        stopTimer();
        console.log("Stoped Timer For Other Questions of Listening.");
      }
    }, [InitStep, step, Category]);
    

  useEffect(() => {
    if (timeLeft === 0) {
      console.log("Time is up, moving to the next question");
      if (Category === "Listening" && questions[step - 1]?.SubCategory === "Summarize Spoken Text") {
        addTime(moment.duration(time, "minutes"));
      } else {
        addTime(moment.duration(time, "minutes") - timeLeft);
      }
      stopTimer(); // Stop the timer when it reaches zero
      setShowRemainingTime(null); // Reset the stopwatch for the next question
      setStep((prev) => prev + 1); // Move to the next question
    }
  }, [timeLeft]);

  return <div>Remaining Time : {moment.utc(timeLeft).format("mm:ss")}</div>;
}
