import React from "react";
import { FlexDiv } from "../assets/styles/style";
import AllReadingComponents from "../components/Reading/AllReadingComponents";



const Reading = () => {
  return (
    <>
      <FlexDiv>
        < AllReadingComponents /> 
      </FlexDiv>
    </>
  );
};

export default Reading;

//Currently This page is being used to render all the cards and components during development 
