import styled from "@emotion/styled";

export const SWT_QCard_Div = styled.div`
  width: 86%;
  margin: 0rem 7% 1.25rem;
  @media (max-width: 1440px) {
    width: 90%;
    margin: 0rem 5% 1.25rem;
  }
  @media (max-width: 1000px) {
    width: 94%;
    margin: 0rem 3% 1.25rem;
  }
`;

export const SWT_MCQCard_Div = styled.div`
  width: 86%;
  margin: 0rem 7% 1.25rem;
  @media (max-width: 1440px) {
    width: 90%;
    margin: 0rem 5% 1.25rem;
  }
  @media (max-width: 1000px) {
    width: 94%;
    margin: 0rem 3% 1.25rem;
  }
`;