import styled from "@emotion/styled";

export const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Btn = styled.button`
    display: contents;
    cursor:pointer;
`;

