import React, { useEffect } from "react";
import { PurpleBtn } from "../Common/Style";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function WelcomeSectional({
  Exam,
  InitStep,
  setInitStep,
  part,
  name,
  totalTime = "00:00",
  speakingTime = null,
  writingTime = null,
  RemainingListeningTime = null,
  summarizeSpokenTextTime = null,
  setIsTestStarted,
  isResuming,
}) {
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");

  const navigate = useNavigate();

  function formatTimeHoursMins(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  function formatTimeMinsSecs(totalMinutes) {
    const mins = Math.floor(totalMinutes); // Get whole minutes
    const seconds = Math.floor((totalMinutes - mins) * 60); // Get the seconds part

    const formattedMinutes = mins < 10 ? `0${mins}` : mins;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const handleNext = () => {
    console.log("PREVIOUS STEP", InitStep);
    setInitStep(InitStep + 1);
    console.log("INCREMENTED STEP", InitStep);
    setIsTestStarted(true);
  };

  const handleExit = () => {
    navigate("/MockTest");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isLaptopTwo ? "1rem 5% 2rem" : "3rem 5% 2rem",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            // height: "400px",
            width: "100%",
            borderRadius: "24px",
            position: "relative",
            overflow: "clip",
          }}
        >
          <div
            style={{
              width: "full",
              minHeight: "50vh",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p style={{ fontSize: "50px", margin: "30px 0px" }}>{Exam}</p>
              <hr />
              {isResuming ? (
                <p>
                  You are resuming part {part} of the exam: <b>{name}</b>
                </p>
              ) : (
                <p>
                  You are about to start part {part} of the exam: <b>{name}</b>
                </p>
              )}

              {name === "Speaking & Writing" && (
                <>
                  <p>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "155px" }}>Total Time Allowed:</div>
                      <div>
                        <b>{formatTimeMinsSecs(totalTime)} minutes</b>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "155px" }}>Speaking Part Time:</div>
                      <div>
                        <b>{formatTimeMinsSecs(speakingTime)} minutes</b>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "155px" }}>Writing Part Time:</div>
                      <div>
                        <b>{formatTimeMinsSecs(writingTime)} minutes</b>
                      </div>
                    </div>
                  </p>
                </>
              )}

              {name === "Reading" && (
                <p>
                  Total Time allowed:{" "}
                  <b>{formatTimeMinsSecs(totalTime)} minutes</b>
                </p>
              )}

              {name === "Listening" && (
                <p>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "195px" }}>
                      Total Time allowed:
                    </div>
                    <div>
                      <b>{formatTimeMinsSecs(totalTime)} minutes</b>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "195px" }}>
                      Summarize Spoken Text:
                    </div>
                    <div>
                      <b>
                        {formatTimeMinsSecs(summarizeSpokenTextTime)} minutes
                      </b>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "195px" }}>
                     Other Questions Time:
                    </div>
                    <div>
                      <b>
                        {formatTimeMinsSecs(RemainingListeningTime)} minutes
                      </b>
                    </div>
                  </div>
                </p>
              )}

              <p>
                Remember to put your headphones on before beginning the session
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <button
                style={{
                  backgroundColor: "#f2f3f7",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 20px",
                  fontFamily: "Noto Sans",
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                onClick={handleExit}
              >
                Save & Exit
              </button>
              <PurpleBtn onClick={handleNext}>Next</PurpleBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
