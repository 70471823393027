import ReadAloudSvg from "../../assets/images/readaloudsvg.svg";
import AI_Score from "../../assets/images/aiscoresvg.svg";

export const CircularScoreProgressData1 = [
  {
    id: 1,
    CircularScoreProgress: [
      {
        key: "Listening",
        HeaderBgColor: "#868EAF",
        Title: "Listening",
        score: "8",
        totalScore: "10",
        progressColorFilled: "#868EAF",
        progressColorUnfilled: "#E5DAFF",
        scoreColor: "#868EAF",
      },
      {
        key: "Reading",
        HeaderBgColor: "#AD826E",
        Title: "Reading",
        score: "3",
        totalScore: "10",
        progressColorFilled: "#AD826E",
        progressColorUnfilled: "#E5DAFF",
        scoreColor: "#AD826E",
      },
      // {
      //   key: "Speaking",
      //   HeaderBgColor: "#49D7F2",
      //   Title: "Speaking",
      //   score: "7",
      //   totalScore: "10",
      //   progressColorFilled: "#49D7F2",
      //   progressColorUnfilled: "#E5DAFF",
      //   scoreColor: "#49D7F2",
      // },
      // {
      //   key: "Writing",
      //   HeaderBgColor: "#FF5D5D",
      //   Title: "Writing",
      //   score: "6",
      //   totalScore: "10",
      //   progressColorFilled: "#FF5D5D",
      //   progressColorUnfilled: "#E5DAFF",
      //   scoreColor: "#FF5D5D",
      // },
    ],
  },
];

export const CircularScoreProgressData2 = [
  {
    id: 1,
    CircularScoreProgress: [
      // {
      //   key: "Listening",
      //   HeaderBgColor: "#868EAF",
      //   Title: "Listening",
      //   score: "8",
      //   totalScore: "10",
      //   progressColorFilled: "#868EAF",
      //   progressColorUnfilled: "#E5DAFF",
      //   scoreColor: "#868EAF",
      // },
      // {
      //   key: "Reading",
      //   HeaderBgColor: "#AD826E",
      //   Title: "Reading",
      //   score: "3",
      //   totalScore: "10",
      //   progressColorFilled: "#AD826E",
      //   progressColorUnfilled: "#E5DAFF",
      //   scoreColor: "#AD826E",
      // },
      {
        key: "Speaking",
        HeaderBgColor: "#49D7F2",
        Title: "Speaking",
        score: "7",
        totalScore: "10",
        progressColorFilled: "#49D7F2",
        progressColorUnfilled: "#E5DAFF",
        scoreColor: "#49D7F2",
      },
      {
        key: "Writing",
        HeaderBgColor: "#FF5D5D",
        Title: "Writing",
        score: "6",
        totalScore: "10",
        progressColorFilled: "#FF5D5D",
        progressColorUnfilled: "#E5DAFF",
        scoreColor: "#FF5D5D",
      },
    ],
  },
];

export const skillsData = [
  {
    title: "Listening",
    score: 37,
    progressColor: "#868EAF",
  },
  {
    title: "Reading",
    score: 80,
    progressColor: "#AD826E",
  },
  {
    title: "Writing",
    score: 39,
    progressColor: "#49D7F2",
  },
  {
    title: "Speaking",
    score: 40,
    progressColor: "#FF5D5D",
  },
];

export const EnablingSkillsData = [
  {
    title: "Grammar",
    skill: 'grammar',
    score: 23,
    progressColor: "#996CFE",
  },
  {
    title: "Oral Fluency",
    skill: 'fluency',
    score: 40,
    progressColor: "#996CFE",
  },
  {
    title: "Pronounciation",
    skill: 'pronounciation',
    score: 75,
    progressColor: "#996CFE",
  },
  {
    title: "Spelling",
    skill: 'spelling',
    score: 34,
    progressColor: "#996CFE",
  },
  {
    title: "Vocabulary",
    skill: 'vocab',
    score: 24,
    progressColor: "#996CFE",
  },
  {
    title: "Written Discourde",
    skill: 'discorude',
    score: 45,
    progressColor: "#996CFE",
  },
];

export const testData = {
  speaking: [
    {
      id: "#1001173",
      question: "Volcano Behaviours",
      questionText: "Question",
      aiScore: true,
      color: "#49D7F2",
      score: "85/100",
      src: AI_Score,
    },
  ],
  writing: [
    {
      id: "#1001174",
      question: "Global Warming Impact",
      questionText: "Question",
      aiScore: false,
      color: "#FF5D5D",
      score: "68/100",
    },
  ],
  reading: [
    {
      id: "#1001175",
      question: "Historical Literature Review",
      questionText: "Question",
      aiScore: false,
      color: "#AD826E",
      score: "74/90",
    },
  ],
  listening: [
    {
      id: "#1001176",
      question: "Modern Music Trends",
      questionText: "Question",
      aiScore: true,
      color: "#868EAF",
      score: "90/100",
      src: AI_Score,
    },
  ],
};
