import React from "react";
import {
  CardBtn,
  CardDateText,
  CardHeaderDiv,
  CardHeaderText,
  CardMainDiv,
} from "./style";
import { Btn, FlexDiv } from "../../assets/styles/style";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Delete from "../../assets/images/DeleteIcon.svg";

const MT_Card = ({
  id,
  headerText = "Mock Test - 1",
  dateText = "Date Taken: 01/12/24",
  onDelete,
  analyticsPath,
  feedbackPath,
  viewScorePath,
}) => {
  const isTab = useMediaQuery("(max-width:750px)");
  const navigate = useNavigate();

  return (
    <>
      <CardHeaderDiv>
        <CardHeaderText>{headerText}</CardHeaderText>
      </CardHeaderDiv>
      <CardMainDiv>
        <CardDateText>{dateText}</CardDateText>
        <FlexDiv
          style={{
            gap: "1rem",
            marginRight: isTab ? "" : "1.5rem",
            flexDirection: isTab ? "column" : "",
          }}
        >
          <FlexDiv style={{ gap: "1rem" }}>
            {/* <CardBtn onClick={() => navigate(`/mt-score-analytics/${id}`)}>
              Analytics
            </CardBtn>
            <Btn onClick={() => navigate(`/mt-score-feedback/${id}`)}>
              <CardBtn>Score Feedback</CardBtn>
            </Btn> */}
            <CardBtn onClick={() => navigate(analyticsPath)}>Analytics</CardBtn>
            <Btn onClick={() => navigate(feedbackPath)}>
              <CardBtn>Score Feedback</CardBtn>
            </Btn>
          </FlexDiv>
          <FlexDiv style={{ gap: "1rem" }}>
            {/* <CardBtn onClick={() => navigate(`/mt-score-viewscore/${id}`)}>
              View Score
            </CardBtn> */}
                <CardBtn onClick={() => navigate(viewScorePath)}>View Score</CardBtn>
            <CardBtn
              style={{
                background: "var(--Accents-Strawberry-Red, #DB3031)",
                width: "5rem",
              }}
              onClick={onDelete}
            >
              <img alt="" src={Delete} />
            </CardBtn>
          </FlexDiv>
        </FlexDiv>
      </CardMainDiv>
    </>
  );
};

export default MT_Card;
