export const SideBarFAQsCardData = [
  [
    {
      id: 1,
      question: "What is PTE Academic ?",
      answer:
        "Pearson Test of English Academic (PTE Academic) is an international computer-based English language test. It provides a measure of a test taker’s language ability to assist education institutions and professional and government organizations that require a standard of academic English language proficiency for admission purposes.",
    },
    {
      id: 2,
      question: "Why are more test takers choosing PTE Academic ?",
      answer:
        "PTE Academic is accepted for student and migration applications across the world. In addition to its unbiased approach to testing, the test has many unique advantages.Did you know that artificial intelligence means that all test taker’s results are graded by an algorithm, not humans?",
    },
    {
      id: 3,
      question: "What is the Test Format ?",
      answer:
        "During the three-hour test session, there are three main parts to the test: speaking and writing (completed together), listening, and reading. There is also an optional 10-minute break between the reading and listening parts. During the test, you will find twenty different question formats, ranging from multiple choice to essay writing and interpreting information.",
    },
    {
      id: 4,
      question: "How to prepare for PTE Academic ?",
      answer:
        "To clear the PTE exam, one should start preparing according to the required target as the preparation can vary with the target that one needs to achieve. We at ALFA have designed our practice platform to make the exam easier and achievable for everyone.",
    },
    {
      id: 5,
      question: "How to book the test?",
      answer:
        "PTE has test centers in over 50 countries & one can book an exam from their official website. A student first needs to register with Pearson by creating an account. The exam is held regularly & a student can choose a preferred date & time, at the nearest available test center.",
    },
  ],
  [
    {
      id: 1,
      question: "About Reading & Writing Fill in the Blanks",
      answer:
        "A passage with several gaps in between will appear on the screen, with a drop-list of options along with each blank. A student will have to choose an appropriate word from this drop-down list to fill in the blank space. This question contributes marks to both the Reading & Writing modules.",
    },
    {
      id: 2,
      question: "About Re-Order Paragraphs",
      answer:
        "Several text boxes will appear jumbled-up or in random order & the student will have to arrange them in the right order. A Student is judged on the ability to understand the organization and cohesion of an academic text.",
    },
    {
      id: 3,
      question: "About Reading Fill in the Blanks",
      answer:
        "A passage with several gaps will appear on the screen & a list of options will be provided at the bottom of the passage. A student will have to drag a word from the below options & drop it at an appropriate blank. Student’s Reading skills will be assessed in this question type.",
    },
    {
      id: 4,
      question: "About Multiple Choice, Single Answer",
      answer:
        "After reading a text, a student will have to answer a multiple-choice question on the content or tone of the text by selecting one response.",
    },
    {
      id: 5,
      question: "About Multiple Choice, Multiple Answers",
      answer:
        "After reading a text, answer a multiple-choice question on the content or tone of the text by selecting more than one response. This question carries negative marking & hence,  a student will get penalized if chosen a wrong answer.",
    },
    {
      id: 6,
      question: "About Read Aloud",
      answer:
        "This is the first question type of the Speaking module. It is one of the most important & scoring questions among all question types. It tests the student’s Speaking & Reading ability.",
    },
    {
      id: 7,
      question: "About Repeat Sentence",
      answer:
        "One of the most scoring & challenging questions of the test, checks upon a student’s Listening & Speaking ability. This question carries partial marking as well.",
    },
    {
      id: 8,
      question: "About Describe Image",
      answer:
        "This is a question wherein the student will have to study & describe an image that appears on the screen. The images vary & the student will have to describe it in an allotted time of 40 seconds.",
    },
    {
      id: 9,
      question: "About Re-tell Lecture",
      answer:
        "This question type would either be an audio or an audio-visual which would play & the student will have to re-tell the lecture in their own words. The time allotted for the response is 40 seconds & the students will be judged on their Listening & Speaking abilities.",
    },
    {
      id: 10,
      question: "About Answer Short Question",
      answer:
        "As the name suggests, this is a short answer question where the response is mostly a single word or a few words. Listening & Speaking skills of the students are assessed in this question type.",
    },
    {
      id: 11,
      question: "About Summarize Spoken Text",
      answer:
        "A student will need to write a summary of an audio lecture in about 50 - 70 words. The student’s Listening & Writing skills will be assessed in this question.",
    },
    {
      id: 12,
      question: "About Write from Dictation",
      answer:
        "This is the last question of both the test and the most important & scoring questions' list. An audio of a sentence will play and after listening to that, the student will have to type the sentence accurately in the box provided. This question contributes marks to both the Listening & Writing module.",
    },
    {
      id: 13,
      question: "About Highlight Incorrect Words",
      answer:
        "The transcript of a recording will appear on the student’s screen and while listening to the recording, he/she will have to identify the words in the transcript that differ from what is said. This question carries negative marks and tests Listening & Reading skills.",
    },
    {
      id: 14,
      question: "About Select Missing Word",
      answer:
        "After listening to a recording, the student will need to select the paragraph that best summarizes the recording. This question type will be assessing the Listening & Reading ability of the student.",
    },
    {
      id: 15,
      question: "About Multiple Choice, Multiple Answers",
      answer:
        "After listening to an audio, answer a multiple-choice question on the content or tone of the text by selecting more than one response. This question carries negative marking & hence,  a student will get penalized if chosen a wrong answer.",
    },
    {
      id: 16,
      question: "About Write from Dictation",
      answer:
        "This is the last question of both the test and the most important & scoring questions' list. An audio of a sentence will play and after listening to that, the student will have to type the sentence accurately in the box provided. This question contributes marks to both the Listening & Writing module.",
    },
    {
      id: 17,
      question: "About Highlight Incorrect Words",
      answer:
        "The transcript of a recording will appear on the student’s screen and while listening to the recording, he/she will have to identify the words in the transcript that differ from what is said. This question carries negative marks and tests Listening & Reading skills.",
    },
    {
      id: 18,
      question: "About Listening Fill in the Blanks",
      answer:
        "A transcript of a recording appears on screen with several gaps. After listening to the recording, a student will have to type the missing word in each gap. This question will be testing a students’ Listening & Writing skills.",
    },
    {
      id: 19,
      question: "About Multiple Choice, Single Answer",
      answer:
        "After listening to an audio, a student will have to answer a multiple-choice question on the content or tone of the text by selecting one response.",
    },
    {
      id: 20,
      question: "About Summarize Written Text",
      answer:
        "A student is required to write a one-sentence summary of a provided passage within a maximum limit of 75 words. This question will check the student’s Reading & Writing Skills.",
    },
    {
      id: 21,
      question: "About Essay",
      answer:
        "A student will have to construct an essay on a given topic in about 200 - 300 words & would be assessed on Writing skills.",
    },
  ],
];

const premiumFeatureslist1 = [
  { text: "AI Scoring", included: true },
  { text: "Prediction Files", included: true },
  { text: "Templates", included: false },
  { text: "Strategy Videos", included: false },
  { text: "Ai Study-Plan", included: false },
  { text: "Full Mock Tests", included: false },
  { text: "Sectional Mock Tests", included: false },
];

const premiumFeatureslist2 = [
  { text: "AI Scoring", included: true },
  { text: "Prediction Files", included: true },
  { text: "Templates", included: true },
  { text: "Strategy Videos", included: true },
  { text: "Ai Study-Plan", included: true },
  { text: "Full Mock Tests", included: true },
  { text: "Sectional Mock Tests", included: false },
];

const premiumFeatureslist3 = [
  { text: "AI Scoring", included: true },
  { text: "Prediction Files", included: true },
  { text: "Templates", included: true },
  { text: "Strategy Videos", included: true },
  { text: "Ai Study-Plan", included: true },
  { text: "Full Mock Tests", included: true },
  { text: "Sectional Mock Tests", included: true },
];

const SubscriptionsData = [
  {
    id: 1,
    heading: "Swiftuni 30 Days",
    description:
      "Lorem ipsum dolor sit amet consectetur. Venenatis facilisis eget mauris suspendisse.",
    price: "PKR 1500",
    premiumAccessPrice: "PKR 1000",
    planName: "Current Plan",
    activeStatus: false,
    days: 30,
    premiumFeatures: premiumFeatureslist3,
  },
  {
    id: 2,
    heading: "Swiftuni 60 Days",
    description:
      "Lorem ipsum dolor sit amet consectetur. Venenatis facilisis eget mauris suspendisse.",
    price: "PKR 3000",
    actualPrice: "PKR 6000",
    premiumAccessPrice: "PKR 1000",
    planName: "Upgrade Now",
    activeStatus: false,
    days: 60,
    premiumFeatures: premiumFeatureslist3,
  },
  {
    id: 3,
    heading: "Swiftuni 90 Days",
    description:
      "Lorem ipsum dolor sit amet consectetur. Venenatis facilisis eget mauris suspendisse.",
    price: "PKR 5000",
    premiumAccessPrice: "PKR 1000",
    planName: "Upgrade Now",
    activeStatus: false,
    days: 90,
    premiumFeatures: premiumFeatureslist3,
  },
];

export const countryCodes = [
  { code: "AF", label: "Afghanistan", dialCode: "+93" },
  { code: "AL", label: "Albania", dialCode: "+355" },
  { code: "DZ", label: "Algeria", dialCode: "+213" },
  { code: "AD", label: "Andorra", dialCode: "+376" },
  { code: "AO", label: "Angola", dialCode: "+244" },
  { code: "AI", label: "Anguilla", dialCode: "+1-264" },
  { code: "AG", label: "Antigua & Barbuda", dialCode: "+1-268" },
  { code: "AR", label: "Argentina", dialCode: "+54" },
  { code: "AM", label: "Armenia", dialCode: "+374" },
  { code: "AW", label: "Aruba", dialCode: "+297" },
  { code: "AU", label: "Australia", dialCode: "+61" },
  { code: "AT", label: "Austria", dialCode: "+43" },
  { code: "AZ", label: "Azerbaijan", dialCode: "+994" },
  { code: "BS", label: "Bahamas", dialCode: "+1-242" },
  { code: "BH", label: "Bahrain", dialCode: "+973" },
  { code: "BD", label: "Bangladesh", dialCode: "+880" },
  { code: "BB", label: "Barbados", dialCode: "+1-246" },
  { code: "BY", label: "Belarus", dialCode: "+375" },
  { code: "BE", label: "Belgium", dialCode: "+32" },
  { code: "BZ", label: "Belize", dialCode: "+501" },
  { code: "BJ", label: "Benin", dialCode: "+229" },
  { code: "BM", label: "Bermuda", dialCode: "+1-441" },
  { code: "BT", label: "Bhutan", dialCode: "+975" },
  { code: "BO", label: "Bolivia", dialCode: "+591" },
  { code: "BA", label: "Bosnia & Herzegovina", dialCode: "+387" },
  { code: "BW", label: "Botswana", dialCode: "+267" },
  { code: "BR", label: "Brazil", dialCode: "+55" },
  { code: "VG", label: "British Virgin Islands", dialCode: "+1-284" },
  { code: "BN", label: "Brunei", dialCode: "+673" },
  { code: "BG", label: "Bulgaria", dialCode: "+359" },
  { code: "BF", label: "Burkina Faso", dialCode: "+226" },
  { code: "BI", label: "Burundi", dialCode: "+257" },
  { code: "KH", label: "Cambodia", dialCode: "+855" },
  { code: "CM", label: "Cameroon", dialCode: "+237" },
  { code: "CA", label: "Canada", dialCode: "+1" },
  { code: "CV", label: "Cape Verde", dialCode: "+238" },
  { code: "KY", label: "Cayman Islands", dialCode: "+1-345" },
  { code: "CF", label: "Central African Republic", dialCode: "+236" },
  { code: "TD", label: "Chad", dialCode: "+235" },
  { code: "CL", label: "Chile", dialCode: "+56" },
  { code: "CN", label: "China", dialCode: "+86" },
  { code: "CO", label: "Colombia", dialCode: "+57" },
  { code: "KM", label: "Comoros", dialCode: "+269" },
  { code: "CG", label: "Congo - Brazzaville", dialCode: "+242" },
  { code: "CD", label: "Congo - Kinshasa", dialCode: "+243" },
  { code: "CK", label: "Cook Islands", dialCode: "+682" },
  { code: "CR", label: "Costa Rica", dialCode: "+506" },
  { code: "HR", label: "Croatia", dialCode: "+385" },
  { code: "CU", label: "Cuba", dialCode: "+53" },
  { code: "CW", label: "Curaçao", dialCode: "+599" },
  { code: "CY", label: "Cyprus", dialCode: "+357" },
  { code: "CZ", label: "Czechia", dialCode: "+420" },
  { code: "CI", label: "Côte d’Ivoire", dialCode: "+225" },
  { code: "DK", label: "Denmark", dialCode: "+45" },
  { code: "DJ", label: "Djibouti", dialCode: "+253" },
  { code: "DM", label: "Dominica", dialCode: "+1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    dialCode: "+1-809, +1-829, +1-849",
  },
  { code: "EC", label: "Ecuador", dialCode: "+593" },
  { code: "EG", label: "Egypt", dialCode: "+20" },
  { code: "SV", label: "El Salvador", dialCode: "+503" },
  { code: "GQ", label: "Equatorial Guinea", dialCode: "+240" },
  { code: "ER", label: "Eritrea", dialCode: "+291" },
  { code: "EE", label: "Estonia", dialCode: "+372" },
  { code: "SZ", label: "Eswatini", dialCode: "+268" },
  { code: "ET", label: "Ethiopia", dialCode: "+251" },
  { code: "FK", label: "Falkland Islands", dialCode: "+500" },
  { code: "FO", label: "Faroe Islands", dialCode: "+298" },
  { code: "FJ", label: "Fiji", dialCode: "+679" },
  { code: "FI", label: "Finland", dialCode: "+358" },
  { code: "FR", label: "France", dialCode: "+33" },
  { code: "GF", label: "French Guiana", dialCode: "+594" },
  { code: "PF", label: "French Polynesia", dialCode: "+689" },
  { code: "GA", label: "Gabon", dialCode: "+241" },
  { code: "GM", label: "Gambia", dialCode: "+220" },
  { code: "GE", label: "Georgia", dialCode: "+995" },
  { code: "DE", label: "Germany", dialCode: "+49" },
  { code: "GH", label: "Ghana", dialCode: "+233" },
  { code: "GI", label: "Gibraltar", dialCode: "+350" },
  { code: "GR", label: "Greece", dialCode: "+30" },
  { code: "GL", label: "Greenland", dialCode: "+299" },
  { code: "GD", label: "Grenada", dialCode: "+1-473" },
  { code: "GP", label: "Guadeloupe", dialCode: "+590" },
  { code: "GT", label: "Guatemala", dialCode: "+502" },
  { code: "GN", label: "Guinea", dialCode: "+224" },
  { code: "GW", label: "Guinea-Bissau", dialCode: "+245" },
  { code: "GY", label: "Guyana", dialCode: "+592" },
  { code: "HT", label: "Haiti", dialCode: "+509" },
  { code: "HN", label: "Honduras", dialCode: "+504" },
  { code: "HK", label: "Hong Kong SAR China", dialCode: "+852" },
  { code: "HU", label: "Hungary", dialCode: "+36" },
  { code: "IS", label: "Iceland", dialCode: "+354" },
  { code: "IN", label: "India", dialCode: "+91" },
  { code: "ID", label: "Indonesia", dialCode: "+62" },
  { code: "IR", label: "Iran", dialCode: "+98" },
  { code: "IQ", label: "Iraq", dialCode: "+964" },
  { code: "IE", label: "Ireland", dialCode: "+353" },
  { code: "IM", label: "Isle of Man", dialCode: "+44-1624" },
  { code: "IL", label: "Israel", dialCode: "+972" },
  { code: "IT", label: "Italy", dialCode: "+39" },
  { code: "JM", label: "Jamaica", dialCode: "+1-876" },
  { code: "JP", label: "Japan", dialCode: "+81" },
  { code: "JE", label: "Jersey", dialCode: "+44-1534" },
  { code: "JO", label: "Jordan", dialCode: "+962" },
  { code: "KZ", label: "Kazakhstan", dialCode: "+7" },
  { code: "KE", label: "Kenya", dialCode: "+254" },
  { code: "KI", label: "Kiribati", dialCode: "+686" },
  { code: "XK", label: "Kosovo", dialCode: "+383" },
  { code: "KW", label: "Kuwait", dialCode: "+965" },
  { code: "KG", label: "Kyrgyzstan", dialCode: "+996" },
  { code: "LA", label: "Laos", dialCode: "+856" },
  { code: "LV", label: "Latvia", dialCode: "+371" },
  { code: "LB", label: "Lebanon", dialCode: "+961" },
  { code: "LS", label: "Lesotho", dialCode: "+266" },
  { code: "LR", label: "Liberia", dialCode: "+231" },
  { code: "LY", label: "Libya", dialCode: "+218" },
  { code: "LI", label: "Liechtenstein", dialCode: "+423" },
  { code: "LT", label: "Lithuania", dialCode: "+370" },
  { code: "LU", label: "Luxembourg", dialCode: "+352" },
  { code: "MO", label: "Macau SAR China", dialCode: "+853" },
  { code: "MG", label: "Madagascar", dialCode: "+261" },
  { code: "MW", label: "Malawi", dialCode: "+265" },
  { code: "MY", label: "Malaysia", dialCode: "+60" },
  { code: "MV", label: "Maldives", dialCode: "+960" },
  { code: "ML", label: "Mali", dialCode: "+223" },
  { code: "MT", label: "Malta", dialCode: "+356" },
  { code: "MQ", label: "Martinique", dialCode: "+596" },
  { code: "MR", label: "Mauritania", dialCode: "+222" },
  { code: "MU", label: "Mauritius", dialCode: "+230" },
  { code: "YT", label: "Mayotte", dialCode: "+262" },
  { code: "MX", label: "Mexico", dialCode: "+52" },
  { code: "MD", label: "Moldova", dialCode: "+373" },
  { code: "MC", label: "Monaco", dialCode: "+377" },
  { code: "MN", label: "Mongolia", dialCode: "+976" },
  { code: "ME", label: "Montenegro", dialCode: "+382" },
  { code: "MS", label: "Montserrat", dialCode: "+1-664" },
  { code: "MA", label: "Morocco", dialCode: "+212" },
  { code: "MZ", label: "Mozambique", dialCode: "+258" },
  { code: "MM", label: "Myanmar (Burma)", dialCode: "+95" },
  { code: "NA", label: "Namibia", dialCode: "+264" },
  { code: "NR", label: "Nauru", dialCode: "+674" },
  { code: "NP", label: "Nepal", dialCode: "+977" },
  { code: "NL", label: "Netherlands", dialCode: "+31" },
  { code: "NC", label: "New Caledonia", dialCode: "+687" },
  { code: "NZ", label: "New Zealand", dialCode: "+64" },
  { code: "NI", label: "Nicaragua", dialCode: "+505" },
  { code: "NE", label: "Niger", dialCode: "+227" },
  { code: "NG", label: "Nigeria", dialCode: "+234" },
  { code: "NU", label: "Niue", dialCode: "+683" },
  { code: "NF", label: "Norfolk Island", dialCode: "+672" },
  { code: "KP", label: "North Korea", dialCode: "+850" },
  { code: "MK", label: "North Macedonia", dialCode: "+389" },
  { code: "NO", label: "Norway", dialCode: "+47" },
  { code: "OM", label: "Oman", dialCode: "+968" },
  { code: "PK", label: "Pakistan", dialCode: "+92" },
  { code: "PW", label: "Palau", dialCode: "+680" },
  { code: "PS", label: "Palestinian Territories", dialCode: "+970" },
  { code: "PA", label: "Panama", dialCode: "+507" },
  { code: "PG", label: "Papua New Guinea", dialCode: "+675" },
  { code: "PY", label: "Paraguay", dialCode: "+595" },
  { code: "PE", label: "Peru", dialCode: "+51" },
  { code: "PH", label: "Philippines", dialCode: "+63" },
  { code: "PL", label: "Poland", dialCode: "+48" },
  { code: "PT", label: "Portugal", dialCode: "+351" },
  { code: "QA", label: "Qatar", dialCode: "+974" },
  { code: "RE", label: "Réunion", dialCode: "+262" },
  { code: "RO", label: "Romania", dialCode: "+40" },
  { code: "RU", label: "Russia", dialCode: "+7" },
  { code: "RW", label: "Rwanda", dialCode: "+250" },
  { code: "WS", label: "Samoa", dialCode: "+685" },
  { code: "SM", label: "San Marino", dialCode: "+378" },
  { code: "ST", label: "São Tomé & Príncipe", dialCode: "+239" },
  { code: "SA", label: "Saudi Arabia", dialCode: "+966" },
  { code: "SN", label: "Senegal", dialCode: "+221" },
  { code: "RS", label: "Serbia", dialCode: "+381" },
  { code: "SC", label: "Seychelles", dialCode: "+248" },
  { code: "SL", label: "Sierra Leone", dialCode: "+232" },
  { code: "SG", label: "Singapore", dialCode: "+65" },
  { code: "SX", label: "Sint Maarten", dialCode: "+1-721" },
  { code: "SK", label: "Slovakia", dialCode: "+421" },
  { code: "SI", label: "Slovenia", dialCode: "+386" },
  { code: "SB", label: "Solomon Islands", dialCode: "+677" },
  { code: "SO", label: "Somalia", dialCode: "+252" },
  { code: "ZA", label: "South Africa", dialCode: "+27" },
  { code: "KR", label: "South Korea", dialCode: "+82" },
  { code: "SS", label: "South Sudan", dialCode: "+211" },
  { code: "ES", label: "Spain", dialCode: "+34" },
  { code: "LK", label: "Sri Lanka", dialCode: "+94" },
  { code: "BL", label: "St. Barthélemy", dialCode: "+590" },
  { code: "SH", label: "St. Helena", dialCode: "+290" },
  { code: "KN", label: "St. Kitts & Nevis", dialCode: "+1-869" },
  { code: "LC", label: "St. Lucia", dialCode: "+1-758" },
  { code: "MF", label: "St. Martin", dialCode: "+590" },
  { code: "PM", label: "St. Pierre & Miquelon", dialCode: "+508" },
  { code: "VC", label: "St. Vincent & Grenadines", dialCode: "+1-784" },
  { code: "SD", label: "Sudan", dialCode: "+249" },
  { code: "SR", label: "Suriname", dialCode: "+597" },
  { code: "SJ", label: "Svalbard & Jan Mayen", dialCode: "+47" },
  { code: "SE", label: "Sweden", dialCode: "+46" },
  { code: "CH", label: "Switzerland", dialCode: "+41" },
  { code: "SY", label: "Syria", dialCode: "+963" },
  { code: "TW", label: "Taiwan", dialCode: "+886" },
  { code: "TJ", label: "Tajikistan", dialCode: "+992" },
  { code: "TZ", label: "Tanzania", dialCode: "+255" },
  { code: "TH", label: "Thailand", dialCode: "+66" },
  { code: "TL", label: "Timor-Leste", dialCode: "+670" },
  { code: "TG", label: "Togo", dialCode: "+228" },
  { code: "TK", label: "Tokelau", dialCode: "+690" },
  { code: "TO", label: "Tonga", dialCode: "+676" },
  { code: "TT", label: "Trinidad & Tobago", dialCode: "+1-868" },
  { code: "TN", label: "Tunisia", dialCode: "+216" },
  { code: "TR", label: "Turkey", dialCode: "+90" },
  { code: "TM", label: "Turkmenistan", dialCode: "+993" },
  { code: "TC", label: "Turks & Caicos Islands", dialCode: "+1-649" },
  { code: "TV", label: "Tuvalu", dialCode: "+688" },
  { code: "UG", label: "Uganda", dialCode: "+256" },
  { code: "UA", label: "Ukraine", dialCode: "+380" },
  { code: "AE", label: "United Arab Emirates", dialCode: "+971" },
  { code: "GB", label: "United Kingdom", dialCode: "+44" },
  { code: "US", label: "United States", dialCode: "+1" },
  { code: "UY", label: "Uruguay", dialCode: "+598" },
  { code: "UZ", label: "Uzbekistan", dialCode: "+998" },
  { code: "VU", label: "Vanuatu", dialCode: "+678" },
  { code: "VA", label: "Vatican City", dialCode: "+39-06" },
  { code: "VE", label: "Venezuela", dialCode: "+58" },
  { code: "VN", label: "Vietnam", dialCode: "+84" },
  { code: "WF", label: "Wallis & Futuna", dialCode: "+681" },
  { code: "YE", label: "Yemen", dialCode: "+967" },
  { code: "ZM", label: "Zambia", dialCode: "+260" },
  { code: "ZW", label: "Zimbabwe", dialCode: "+263" },
];
