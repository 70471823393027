import React from "react";
import Navbar from "../components/Navbar/Navbar";
import CheckoutInitial from "../components/SideBar/CheckoutInitial";

const Checkout = () => {
  

  return (
    <>
      <Navbar />
      <CheckoutInitial />
    </>
  );
};

export default Checkout;
