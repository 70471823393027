import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  DraggableStyledCard,
  DraggableCardText,
  StyledCardContent,
} from "./Style";

// const DraggableCard = ({ id, text, index, moveCard }) => {
//   const ref = useRef(null);
//   const [{ handlerId }, drop] = useDrop({
//     accept: "CARD",
//     collect(monitor) {
//       return {
//         handlerId: monitor.getHandlerId(),
//       };
//     },
//     hover(item, monitor) {
//       if (!ref.current) {
//         return;
//       }
//       const dragIndex = item.index;
//       const hoverIndex = index;
//       if (dragIndex === hoverIndex) {
//         return;
//       }
//       moveCard(dragIndex, hoverIndex);
//       item.index = hoverIndex;
//     },
//   });
//   const [{ isDragging }, drag] = useDrag({
//     type: "CARD",
//     item: () => {
//       return { id, index };
//     },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });
//   drag(drop(ref));

//   return (
//     <DraggableStyledCard
//       ref={ref}
//       style={{
//         opacity: isDragging ? 0 : 1,
//       }}
//       data-handler-id={handlerId}
//     >
//       <StyledCardContent>
//         {`${index + 1})`}
//         <DraggableCardText>{`${text}`}</DraggableCardText>
//       </StyledCardContent>
//     </DraggableStyledCard>
//   );
// };

// export default DraggableCard;

const DraggableCard = ({ id, text, index, moveCard, originalIndex }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <DraggableStyledCard
      ref={ref}
      isDragging={isDragging}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
    >
      <StyledCardContent>
        {`${originalIndex + 1})`}
        <DraggableCardText>{text}</DraggableCardText>
      </StyledCardContent>
    </DraggableStyledCard>
  );
};

export default DraggableCard;
