import React, { useState, useEffect } from "react";
import { FlexDiv } from "../../assets/styles/style";
import {
  CardHeader,
  CardHeaderText,
  HeaderText,
  EnableSkillsHeader,
  AiScoreSmallCard,
  AiScoreSmallCardHeader,
  UserResponseHeader,
  ContentWrapper1,
  WritingOutOfText,
  Flexed1,
  Flexed2,
  AiScoreParagraphText,
} from "../Writing/style";
import {
  ListeningPopupWrapper,
  EnableSkillsCard,
  ResponseCard,
  ResponseContentWrapper,
  ResponseContentWrapper2,
  UserResListsHeading,
  UserResLists,
  UserResListsHeadingWFD,
} from "./styles";
import CircularScoreProgress from "../Writing/CircularScoreProgress";
import EnableSkillsScoreTable from "../Writing/EnableSkillsScoreTable";
import { useMediaQuery } from "@mui/material";
import CancelIcon from "../../assets/images/carbon_close-filled.svg";

// const InsertMissedWords = (str, missedWords, missedWordsIndices) => {
//   let result = "";
//   let lastIndex = 0;
//   let temp = str;
//   let tempCounter = 0;

//   for (let i = 0; i < missedWordsIndices.length; i++) {
//     let [start, end] = missedWordsIndices[i];
//     start = start + tempCounter;
//     end = end + tempCounter;
//     if (start === 0) {
//       for (let i = start; i < end; i++) {
//         if (i === 0) {
//           temp = "* " + temp;
//           tempCounter += 2;
//         } else {
//           temp = "*" + temp;
//           tempCounter += 1;
//         }
//       }
//       temp = temp.replace(temp.slice(start, end), missedWords[i]);
//     } else {
//       if (temp[start] === " ") {
//         for (let i = start; i < end; i++) {
//           if (i === start) {
//             temp = temp.slice(0, i) + " *" + temp.slice(i);
//             tempCounter += 2;
//           } else if (i === end - 1) {
//             temp = temp.slice(0, i) + "* " + temp.slice(i);
//             tempCounter += 2;
//           } else {
//             temp = temp.slice(0, i) + "*" + temp.slice(i);
//             tempCounter += 1;
//           }
//         }
//         temp = temp.replace(temp.slice(start + 1, end + 1), missedWords[i]);
//       } else {
//         let tmpStart = start;
//         let tmpEnd = end;
//         let count = 0;
//         for (let i = tmpStart; i < temp.length; i++) {
//           if (temp[i] === " ") {
//             i = temp.length;
//           } else {
//             count += 1;
//           }
//         }
//         start = start + count;
//         end = end + count;
//         for (let i = start; i < end - 1; i++) {
//           if (i === start) {
//             temp = temp.slice(0, i) + " *" + temp.slice(i);
//             tempCounter += 2;
//           } else if (i === end - 2) {
//             temp = temp.slice(0, i) + "* " + temp.slice(i);
//             tempCounter += 2;
//           } else {
//             temp = temp.slice(0, i) + "*" + temp.slice(i);
//             tempCounter += 1;
//           }
//         }
//         temp = temp.replace(temp.slice(start + 1, end + 1), missedWords[i]);
//       }
//     }
//   }

//   temp = temp.charAt(0).toUpperCase() + temp.slice(1);
//   return temp.replace(/\*/g, "");
// };

function mergeWords(correctWords, matchingIndices, missedWords, missedWordsIndices, incorrectWords, incorrectIndices) {
  let combined = [];

  if (correctWords?.length && matchingIndices?.length) {
    correctWords.forEach((word, index) => {
      combined.push({
        word: word,
        index: matchingIndices[index],
        status: "correct"
      });
    });
  }

  if (incorrectWords?.length && incorrectIndices?.length) {
    incorrectWords.forEach((word, index) => {
      combined.push({
        word: word,
        index: incorrectIndices[index],
        status: "incorrect"
      });
    });
  }

  if (missedWords?.length && missedWordsIndices?.length) {
    missedWords.forEach((word, index) => {
      combined.push({
        word: word,
        index: missedWordsIndices[index],
        status: "missed"
      });
    });
  }

  // Sort combined array based on the start index of each word
  combined.sort((a, b) => a.index[0] - b.index[0]);

  return combined;
}

const colorIndexing = {
  "correct": "#008000",
  "incorrect": "#FF8743",
  "missed": "#289EAE"
}

// function getWordIndices(sentence) {
//   let indices = [];
//   let words = sentence.split(' ');
//   let startIndex = 0;

//   words.forEach(word => {
//     let endIndex = startIndex + word.length;
//     indices.push([startIndex, endIndex]);
//     startIndex = endIndex + 2; // +2 to account for the space after the word
//   });

//   return indices;
// }

const HighlightedResponse = ({
  userResponse,
  incorrectIndices,
  incorrectWords,
  missedWords,
  missedWordsIndices,
  correctWords,
  matchingIndices,
  correctResponse
}) => {
  let styledParts = mergeWords(correctWords, matchingIndices, missedWords, missedWordsIndices, incorrectWords, incorrectIndices);
  const elements = [];
  if(styledParts?.length){
    styledParts.forEach((word, index) => {
      elements.push(
        <span
          key={`text-${index}`}
          style={{ color: colorIndexing[word.status] }}
          dangerouslySetInnerHTML={{ __html: word.word }}
        />
      );
      elements.push(
        <span key={`text-${word}`} dangerouslySetInnerHTML={{ __html: " " }} />
      );      
    });
  }else{
    elements.push(
      <span
        key={`text-${i}`}
        dangerouslySetInnerHTML={{ __html: userResponse }}
      />
    );    
  }

  return <div>{elements}</div>;
};

function formatCorrectAnswer(answer) {
  // If answer is an array, join with spaces
  if (Array.isArray(answer)) {
    answer = answer.join(' ');
  }

  // Ensure the answer is a string before proceeding
  if (typeof answer === 'string') {
    // Check if there are no spaces and add them between camelCased words if necessary
    if (!/\s/.test(answer)) {
      return answer.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
  }

    // Add a period at the end if there isn't one
    if (!answer.endsWith('.')) {
      answer += '.';
    }

  return answer;
}


const AiScorePopupListeningWFD = ({
  close,
  dataKey,
  SmallScoreCard,
  UserResponse,
  correctAnswer,
  wfdScore,
}) => {
  const isMobile = useMediaQuery("(max-width:750px)");
  // console.log(wfdScore);
  const calculateWidth = (elements) => {
    const numElements = elements;
    if (numElements > 1) {
      return `${Math.floor(100 / numElements) - 1}%`;
    } else {
      return `${Math.floor(100 / numElements)}%`;
    }
  };

  return (
    <FlexDiv
      style={{ justifyContent: "center", zIndex: "1001", width: "100%" }}
    >
      <ListeningPopupWrapper id="scorecard">
        <CardHeader>
          <CardHeaderText>Ai Score</CardHeaderText>
          <FlexDiv style={{ position: "absolute", right: "1rem" }}>
            <img
              src={CancelIcon}
              alt=""
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => close(false)}
            />
          </FlexDiv>
        </CardHeader>

        <Flexed1>
          {!isMobile && (
            <Flexed2>
              {SmallScoreCard.map((scoreCard, index) => (
                <AiScoreSmallCard
                  BorderColor={scoreCard.HeaderBgColor}
                  key={index}
                >
                  <AiScoreSmallCardHeader BgColor={scoreCard.HeaderBgColor}>
                    <HeaderText>{scoreCard.Heading}</HeaderText>
                  </AiScoreSmallCardHeader>
                  <ContentWrapper1>
                    <CircularScoreProgress
                      score={wfdScore.writing_score}
                      totalScore={wfdScore.total_score}
                      progressColorFilled={scoreCard.progressColorFilled}
                      scoreColor={scoreCard.scoreColor}
                      progressColorUnfilled={scoreCard.progressColorUnfilled}
                    />
                    <WritingOutOfText>
                      Out of {wfdScore.total_score}
                    </WritingOutOfText>
                  </ContentWrapper1>
                </AiScoreSmallCard>
              ))}
            </Flexed2>
          )}
          <FlexDiv
            style={{
              flexDirection: "column",
              gap: "12px",
              width: isMobile ? "100%" : "90%",
            }}
          >
            <EnableSkillsCard>
              <EnableSkillsHeader>
                <HeaderText>Enabling Skills</HeaderText>
              </EnableSkillsHeader>
              <EnableSkillsScoreTable
                rows={[
                  {
                    component: "Words",
                    score: `${wfdScore.writing_score}/${wfdScore.total_score}`,
                    suggestion: wfdScore.writing_score > 0 ? "Good attempt!" : "Please try to improve your listening skills.",
                  },
                ]}
              />
            </EnableSkillsCard>
            <ResponseCard style={{ width: "100%" }}>
              <UserResponseHeader>
                <HeaderText>Correct Answer</HeaderText>
              </UserResponseHeader>
              <ResponseContentWrapper>
                <AiScoreParagraphText key={dataKey}>
                  {/* {correctAnswer} */}
                  {formatCorrectAnswer(correctAnswer)}
                </AiScoreParagraphText>
              </ResponseContentWrapper>
            </ResponseCard>
            <ResponseCard style={{ width: "100%" }}>
              <UserResponseHeader>
                <HeaderText>User's Response</HeaderText>
              </UserResponseHeader>
              <ResponseContentWrapper2>
                <AiScoreParagraphText key={dataKey}>
                  <HighlightedResponse
                    userResponse={UserResponse}
                    correctResponse={correctAnswer}
                    correctWords={wfdScore.correct_words}
                    matchingIndices={wfdScore.matching_indices}
                    missedWords={wfdScore.missed_words}
                    missedWordsIndices={wfdScore.missed_words_indices}
                    incorrectWords={wfdScore.incorrect_words}
                    incorrectIndices={wfdScore.incorrect_indices}
                  />
                </AiScoreParagraphText>

                <FlexDiv
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <UserResLists>
                    <UserResListsHeadingWFD color={"#008000"}>
                      Correct Words
                    </UserResListsHeadingWFD>
                  </UserResLists>
                  <UserResLists>
                    <UserResListsHeadingWFD color={"#289EAE"}>
                      Missed Words
                    </UserResListsHeadingWFD>
                  </UserResLists>
                  <UserResLists>
                    <UserResListsHeadingWFD color={"#FF8743"}>
                      Misspelt / Wrong Words
                    </UserResListsHeadingWFD>
                  </UserResLists>
                </FlexDiv>
              </ResponseContentWrapper2>
            </ResponseCard>

            {isMobile && (
              <FlexDiv
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                {SmallScoreCard.map((scoreCard, index) => (
                  <AiScoreSmallCard
                    BorderColor={scoreCard.HeaderBgColor}
                    key={index}
                    style={{
                      width: isMobile
                        ? calculateWidth(SmallScoreCard.length)
                        : "",
                    }}
                  >
                    <AiScoreSmallCardHeader BgColor={scoreCard.HeaderBgColor}>
                      <HeaderText>{scoreCard.Heading}</HeaderText>
                    </AiScoreSmallCardHeader>
                    <ContentWrapper1>
                      <CircularScoreProgress
                        score={wfdScore.writing_score}
                        totalScore={wfdScore.total_score}
                        progressColorFilled={scoreCard.progressColorFilled}
                        scoreColor={scoreCard.scoreColor}
                        progressColorUnfilled={scoreCard.progressColorUnfilled}
                      />
                      <WritingOutOfText>
                        Out of {wfdScore.total_score}
                      </WritingOutOfText>
                    </ContentWrapper1>
                  </AiScoreSmallCard>
                ))}
              </FlexDiv>
            )}
          </FlexDiv>
        </Flexed1>
      </ListeningPopupWrapper>
    </FlexDiv>
  );
};

export default AiScorePopupListeningWFD;
