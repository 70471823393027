import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { FlexDiv } from "../../assets/styles/style";
import {
  FeedbackHeader,
  FeedbackHeaderText,
  FeedbackHeaderTitle,
} from "./style";
import FeedbackCard from "./FeedbackCard";
import SetTargetPopup from "../Home/SetTargetPopup";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import getFeedback from "../ScoreFeedback/getFeedback";
import LoadingModal from "../Common/LoadingModal";
import { Base_URL } from "../../Client/apiURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const modalStyle = {
  overlay: {
    zIndex: 1002,
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    background: "none",
  },
  content: {
    border: "none",
    background: "transparent",
    inset: "0px",
    padding: "20px 1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const TestsNames = {
  Speaking: [
    "Read Aloud",
    "Repeat Sentence",
    "Describe Image",
    "Re-tell Lecture",
    "Answer Short Question",
  ],
  Writing: [
    "Summarize Written Text",
    "Write Essay",
    "Reading & Writing: Fill in the Blanks",
    "Summarize Spoken Text",
    "Fill in the Blanks",
    "Write from Dictation",
  ],
  Reading: [
    "Read Aloud",
    "Summarize Written Text",
    "Reading & Writing: Fill in the Blanks",
    "Multiple Choice, Multiple Answers",
    "Re-order Paragraphs",
    "Reading: Fill in the Blanks",
    "Multiple Choice, Single Answer",
    "Highlight Correct Summary",
    "Highlight Incorrect Words",
  ],
  Listening: [
    "Repeat Sentence",
    "Re-tell Lecture",
    "Answer Short Question",
    "Summarize Spoken Text",
    "Listening: Multiple Choice, Multiple Answers",
    "Fill in the Blanks",
    "Highlight Correct Summary",
    "Highlight Incorrect Words",
    "Listening: Multiple Choice, Single Answer",
    "Select Missing Word",
    "Write from Dictation",
  ],
};

const FeedBackSectional = () => {
  const isTab = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [startValue, setStartValue] = useState();
  const [readingTarget, setReadingTarget] = useState(null);
  const [writingTarget, setWritingTarget] = useState(null);
  const [speakingTarget, setSpeakingTarget] = useState(null);
  const [listeningTarget, setListeningTarget] = useState(null);
  const [endValue, setEndValue] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [mockTestsResult, setMockTestsResult] = useState([]);
  const [listeningTests, setListeningTests] = useState([]);
  const [readingTests, setReadingTests] = useState([]);
  const [writingTests, setWritingTests] = useState([]);
  const [speakingTests, setSpeakingTests] = useState([]);
  const [enableSkillTests, setEnableSkillTests] = useState([]);
  const [testCounts, setTestCounts] = useState(null);
  const isFirstRender = useRef(true);
  const [targetRange, setTargetRange] = useState("");
  const [scores, setScores] = useState({
    overall: null,
    listening: null,
    reading: null,
    writing: null,
    speaking: null,
  });
  const [feedback, setFeedback] = useState({
    overall: "",
    reading: "",
    writing: "",
    listening: "",
    speaking: "",
  });

  const [categoryScores, setCategoryScores] = useState({
    raTotal: 0,
    raObtain: 0,
    rsTotal: 0,
    rsObtain: 0,
    diTotal: 0,
    diObtain: 0,
    rlTotal: 0,
    rlObtain: 0,
    asqTotal: 0,
    asqObtain: 0,
    swtTotal: 0,
    swtObtain: 0,
    weTotal: 0,
    weObtain: 0,
    rwfibTotal: 0,
    rwfibObtain: 0,
    rfibTotal: 0,
    rfibObtain: 0,
    mcmaTotal: 0,
    mcmaObtain: 0,
    mcsaTotal: 0,
    mcsaObtain: 0,
    ropTotal: 0,
    ropObtain: 0,
    sstTotal: 0,
    sstObtain: 0,
    lmcmaTotal: 0,
    lmcmaObtain: 0,
    lmcsaTotal: 0,
    lmcsaObtain: 0,
    hiwTotal: 0,
    hiwObtain: 0,
    fibTotal: 0,
    fibObtain: 0,
    wfdTotal: 0,
    wfdObtain: 0,
    hcsTotal: 0,
    hcsObtain: 0,
    smwTotal: 0,
    smwObtain: 0,
  });
  const [mockTestType, setMockTestType] = useState("");

  useEffect(() => {
    fetchData();
    getTargetRange();
  }, []);

  const getTargetRange = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Base_URL}/app/users/get-examdate`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok && data.response) {
        const examTarget = JSON.parse(data.response.ExamTarget);

        if (examTarget && examTarget.examTargetRange) {
          let formattedRange = examTarget.examTargetRange.trim();

          if (formattedRange === "79+") {
            formattedRange = "79-90";
          } else {
            formattedRange = formattedRange.replace(/\s*-\s*/g, "-");
          }
          setTargetRange(formattedRange);
          const startValue = parseInt(formattedRange.split("-")[0]);
          setStartValue(startValue);
          setReadingTarget(examTarget.reading || "N/A");
          setWritingTarget(examTarget.writing || "N/A");
          setSpeakingTarget(examTarget.speaking || "N/A");
          setListeningTarget(examTarget.listening || "N/A");
          setOpen(false);
        } else {
          setOpen(true);
        }
      } else {
        console.error("Failed to fetch target range: No data available.");
        setOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch exam date:", error);
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSetTarget = (start, end) => {
    setTargetRange(`${start}-${end}`);
    setStartValue(start);
    setEndValue(end);
    setOpen(false);
  };

  useEffect(() => {
    if (!open && !isFirstRender.current) {
      setLoading(true);
      setTimeout(() => {
        updateScores();
        setLoading(false);
      }, 2000);
    }
    isFirstRender.current = false;
  }, [open, mockTestsResult, targetRange]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${Base_URL}/app/users/mock-test-attempts/get-score?mock_test_attempted_id=${id}`,
        { withCredentials: true }
      );

      if (
        response.status === 200 &&
        response.data.message === "Mock Test Score:"
      ) {
        const parsedResponses = response.data.response.map((item) => {
          let userResponse = item.UserResponse;

          try {
            if (userResponse) {
              userResponse = JSON.parse(userResponse);
              if (typeof userResponse === "string") {
                userResponse = JSON.parse(userResponse);
              }
            }
          } catch (e) {
            console.error("Error parsing UserResponse:", e);
          }

          return {
            ...item,
            UserResponse: userResponse,
          };
        });

        // Check if all UserResponses are null
        const hasScore = parsedResponses.some(
          (item) => item.UserResponse !== null
        );

        if (hasScore) {
          const testType = parsedResponses[0]?.UserResponse?.mockTestType || "";
          setMockTestType(testType);
          setMockTestsResult(parsedResponses);
          splitCategories(parsedResponses);
        } else {
          navigate("/mt-score");
          toast.error("No score available");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/mt-score");
      toast.error("Failed to fetch data");
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${Base_URL}/app/users/mock-test-attempts/get-score?mock_test_attempted_id=${id}`,
  //       { withCredentials: true }
  //     );
  //     if (response.status === 200 && response.data.message === "Mock Test Score:") {
  //       const parsedResponses = response.data.response.map((item) => {
  //         let userResponse = item.UserResponse;
  //         try {
  //           userResponse = JSON.parse(userResponse);
  //           if (typeof userResponse === "string") {
  //             userResponse = JSON.parse(userResponse);
  //           }
  //         } catch (e) {
  //           console.error("Error parsing UserResponse:", e);
  //         }
  //         return {
  //           ...item,
  //           UserResponse: userResponse,
  //         };
  //       });

  //       setMockTestsResult(parsedResponses);
  //       splitCategories(parsedResponses);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const splitCategories = (data) => {
    const categorizedData = data.reduce(
      (acc, curr) => {
        switch (curr.UserResponse.SubCategory) {
          case "Write Essay":
            acc.writing.push(curr);
            acc.enableSkills.push(curr);
            acc.we.push(curr);
            break;
          case "Summarize Written Text":
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.enableSkills.push(curr);
            acc.swt.push(curr);
            break;
          case "Reading & Writing: Fill in the Blanks":
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.rwfib.push(curr);
            break;
          case "Summarize Spoken Text":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.enableSkills.push(curr);
            acc.sst.push(curr);
            break;
          case "Fill in the Blanks":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.fib.push(curr);
            break;
          case "Write from Dictation":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.wfd.push(curr);
            break;
          case "Read Aloud":
            acc.reading.push(curr);
            acc.speaking.push(curr);
            acc.enableSkills.push(curr);
            acc.ra.push(curr);
            break;
          case "Multiple Choice, Multiple Answers":
            acc.reading.push(curr);
            acc.mcma.push(curr);
            break;
          case "Re-order Paragraphs":
            acc.reading.push(curr);
            acc.rop.push(curr);
            break;
          case "Multiple Choice, Single Answer":
            acc.reading.push(curr);
            acc.mcsa.push(curr);
            break;
          case "Highlight Correct Summary":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hcs.push(curr);
            break;
          case "Highlight Incorrect Words":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hiw.push(curr);
            break;
          case "Reading: Fill in the Blanks":
            acc.reading.push(curr);
            acc.rfib.push(curr);
            break;
          case "Repeat Sentence":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.enableSkills.push(curr);
            acc.rs.push(curr);
            break;
          case "Describe Image":
            acc.speaking.push(curr);
            acc.enableSkills.push(curr);
            acc.di.push(curr);
            break;
          case "Re-tell Lecture":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.enableSkills.push(curr);
            acc.rl.push(curr);
            break;
          case "Answer Short Question":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.asq.push(curr);
            break;
          case "Listening: Multiple Choice, Multiple Answers":
            acc.listening.push(curr);
            acc.lmcma.push(curr);
            break;
          case "Listening: Multiple Choice, Single Answer":
            acc.listening.push(curr);
            acc.lmcsa.push(curr);
            break;
          case "Select Missing Word":
            acc.listening.push(curr);
            acc.smw.push(curr);
            break;
          default:
            acc.listening.push(curr);
            break;
        }
        return acc;
      },
      {
        listening: [],
        reading: [],
        writing: [],
        speaking: [],
        enableSkills: [],
        ra: [],
        rs: [],
        di: [],
        rl: [],
        asq: [],
        swt: [],
        we: [],
        rwfib: [],
        rfib: [],
        mcma: [],
        mcsa: [],
        rop: [],
        sst: [],
        lmcma: [],
        lmcsa: [],
        hiw: [],
        fib: [],
        wfd: [],
        hcs: [],
        smw: [],
      }
    );

    setEnableSkillTests(categorizedData.enableSkills);
    setListeningTests(categorizedData.listening);
    setReadingTests(categorizedData.reading);
    setWritingTests(categorizedData.writing);
    setSpeakingTests(categorizedData.speaking);

    setTestCounts([
      categorizedData.ra,
      categorizedData.rs,
      categorizedData.di,
      categorizedData.rl,
      categorizedData.asq,
      categorizedData.swt,
      categorizedData.we,
      categorizedData.rwfib,
      categorizedData.rfib,
      categorizedData.mcma,
      categorizedData.mcsa,
      categorizedData.rop,
      categorizedData.sst,
      categorizedData.lmcma,
      categorizedData.lmcsa,
      categorizedData.hiw,
      categorizedData.fib,
      categorizedData.wfd,
      categorizedData.hcs,
      categorizedData.smw,
    ]);
  };

  const updateScores = () => {
    calculateCategoryScores();
    calculateTotalScores();
  };

  const calculateCategoryScores = () => {
    let totalMark = {
      "Read Aloud": 0,
      "Repeat Sentence": 0,
      "Describe Image": 0,
      "Re-tell Lecture": 0,
      "Answer Short Question": 0,
      "Summarize Written Text": 0,
      "Write Essay": 0,
      "Reading & Writing: Fill in the Blanks": 0,
      "Reading: Fill in the Blanks": 0,
      "Multiple Choice, Multiple Answers": 0,
      "Multiple Choice, Single Answer": 0,
      "Re-order Paragraphs": 0,
      "Summarize Spoken Text": 0,
      "Listening: Multiple Choice, Multiple Answers": 0,
      "Listening: Multiple Choice, Single Answer": 0,
      "Highlight Incorrect Words": 0,
      "Fill in the Blanks": 0,
      "Write from Dictation": 0,
      "Highlight Correct Summary": 0,
      "Select Missing Word": 0,
    };
    let obtainMark = {
      "Read Aloud": 0,
      "Repeat Sentence": 0,
      "Describe Image": 0,
      "Re-tell Lecture": 0,
      "Answer Short Question": 0,
      "Summarize Written Text": 0,
      "Write Essay": 0,
      "Reading & Writing: Fill in the Blanks": 0,
      "Reading: Fill in the Blanks": 0,
      "Multiple Choice, Multiple Answers": 0,
      "Multiple Choice, Single Answer": 0,
      "Re-order Paragraphs": 0,
      "Summarize Spoken Text": 0,
      "Listening: Multiple Choice, Multiple Answers": 0,
      "Listening: Multiple Choice, Single Answer": 0,
      "Highlight Incorrect Words": 0,
      "Fill in the Blanks": 0,
      "Write from Dictation": 0,
      "Highlight Correct Summary": 0,
      "Select Missing Word": 0,
    };

    if (testCounts && Array.isArray(testCounts)) {
      testCounts.forEach((test) => {
        test.forEach((result) => {
          const subCategory = result["UserResponse"]["SubCategory"];
          const userResponse = result["UserResponse"];
          let aiResponse = userResponse["AI_response"];
          if (aiResponse && typeof aiResponse === "string") {
            aiResponse = JSON.parse(aiResponse);
          }
  
          switch (subCategory) {
            case "Read Aloud":
              totalMark["Read Aloud"] += 15;
              let pronounScore =
                aiResponse.pronunciation_score || aiResponse.pronounciation_score;
              obtainMark["Read Aloud"] +=
                aiResponse.content_score +
                aiResponse.fluency_score +
                pronounScore;
              break;
            case "Repeat Sentence":
              totalMark["Repeat Sentence"] += 13;
              obtainMark["Repeat Sentence"] +=
                aiResponse.content_score +
                aiResponse.fluency_score +
                aiResponse.pronunciation_score;
              break;
            case "Describe Image":
              totalMark["Describe Image"] += 15;
              obtainMark["Describe Image"] +=
                aiResponse.content_score +
                aiResponse.fluency_score +
                aiResponse.pronounciation_score;
              break;
            case "Re-tell Lecture":
              totalMark["Re-tell Lecture"] += 15;
              obtainMark["Re-tell Lecture"] +=
                aiResponse.content_score +
                aiResponse.fluency_score +
                aiResponse.pronounciation_score;
              break;
            case "Answer Short Question":
              totalMark["Answer Short Question"] += 1;
              obtainMark["Answer Short Question"] += aiResponse["content_score"];
              break;
            case "Summarize Written Text":
              totalMark["Summarize Written Text"] += 8;
              obtainMark["Summarize Written Text"] += aiResponse.total_score;
              break;
            case "Write Essay":
              totalMark["Write Essay"] += 15;
              obtainMark["Write Essay"] += aiResponse.total_score;
              break;
            case "Reading & Writing: Fill in the Blanks":
              totalMark["Reading & Writing: Fill in the Blanks"] +=
                userResponse.correctAnswers.length;
              let rwfibScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Reading & Writing: Fill in the Blanks"] += parseInt(
                rwfibScore[0]
              );
              break;
            case "Reading: Fill in the Blanks":
              totalMark["Reading: Fill in the Blanks"] +=
                userResponse.correctAnswers.length;
              let rfibScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Reading: Fill in the Blanks"] += parseInt(rfibScore[0]);
              break;
            case "Multiple Choice, Multiple Answers":
              totalMark["Multiple Choice, Multiple Answers"] +=
                userResponse.correctAnswers.length;
              let mcmaScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Multiple Choice, Multiple Answers"] += parseInt(
                mcmaScore[0]
              );
              break;
            case "Multiple Choice, Single Answer":
              totalMark["Multiple Choice, Single Answer"] += 1;
              let mcsaScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Multiple Choice, Single Answer"] += parseInt(
                mcsaScore[0]
              );
              break;
            case "Re-order Paragraphs":
              let ropResult = userResponse["submissionResult"];
              totalMark["Re-order Paragraphs"] += ropResult.correctIndexes.length;
              obtainMark["Re-order Paragraphs"] += ropResult.score;
              break;
            case "Summarize Spoken Text":
              totalMark["Summarize Spoken Text"] += 10;
              obtainMark["Summarize Spoken Text"] += aiResponse.total_score;
              break;
            case "Listening: Multiple Choice, Multiple Answers":
              totalMark["Listening: Multiple Choice, Multiple Answers"] +=
                userResponse.correctAnswers.length;
              let lmcmaScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Listening: Multiple Choice, Multiple Answers"] +=
                parseInt(lmcmaScore[0]);
              break;
            case "Listening: Multiple Choice, Single Answer":
              totalMark["Listening: Multiple Choice, Single Answer"] += 1;
              let lmcsaScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Listening: Multiple Choice, Single Answer"] += parseInt(
                lmcsaScore[0]
              );
              break;
            case "Highlight Incorrect Words":
              totalMark["Highlight Incorrect Words"] +=
                userResponse.correctAnswers.length;
              let hiwScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Highlight Incorrect Words"] += parseInt(hiwScore[0]);
              break;
            case "Fill in the Blanks":
              totalMark["Fill in the Blanks"] +=
                userResponse.correctAnswers.length;
              let fibScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Fill in the Blanks"] += parseInt(fibScore[0]);
              break;
            case "Write from Dictation":
              totalMark["Write from Dictation"] += aiResponse.total_score;
              obtainMark["Write from Dictation"] += aiResponse.writing_score;
              break;
            case "Highlight Correct Summary":
              totalMark["Highlight Correct Summary"] += 1;
              let hcsScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Highlight Correct Summary"] += parseInt(hcsScore[0]);
              break;
            case "Select Missing Word":
              totalMark["Select Missing Word"] += 1;
              let smwScore = userResponse.enableSkillsData[0]["score"];
              obtainMark["Select Missing Word"] += parseInt(smwScore[0]);
              break;
            default:
              break;
          }
        });
      });
    }



    setCategoryScores({
      raTotal: totalMark["Read Aloud"],
      raObtain: obtainMark["Read Aloud"],
      rsTotal: totalMark["Repeat Sentence"],
      rsObtain: obtainMark["Repeat Sentence"],
      diTotal: totalMark["Describe Image"],
      diObtain: obtainMark["Describe Image"],
      rlTotal: totalMark["Re-tell Lecture"],
      rlObtain: obtainMark["Re-tell Lecture"],
      asqTotal: totalMark["Answer Short Question"],
      asqObtain: obtainMark["Answer Short Question"],
      swtTotal: totalMark["Summarize Written Text"],
      swtObtain: obtainMark["Summarize Written Text"],
      weTotal: totalMark["Write Essay"],
      weObtain: obtainMark["Write Essay"],
      rwfibTotal: totalMark["Reading & Writing: Fill in the Blanks"],
      rwfibObtain: obtainMark["Reading & Writing: Fill in the Blanks"],
      rfibTotal: totalMark["Reading: Fill in the Blanks"],
      rfibObtain: obtainMark["Reading: Fill in the Blanks"],
      mcmaTotal: totalMark["Multiple Choice, Multiple Answers"],
      mcmaObtain: obtainMark["Multiple Choice, Multiple Answers"],
      mcsaTotal: totalMark["Multiple Choice, Single Answer"],
      mcsaObtain: obtainMark["Multiple Choice, Single Answer"],
      ropTotal: totalMark["Re-order Paragraphs"],
      ropObtain: obtainMark["Re-order Paragraphs"],
      sstTotal: totalMark["Summarize Spoken Text"],
      sstObtain: obtainMark["Summarize Spoken Text"],
      lmcmaTotal: totalMark["Listening: Multiple Choice, Multiple Answers"],
      lmcmaObtain: obtainMark["Listening: Multiple Choice, Multiple Answers"],
      lmcsaTotal: totalMark["Listening: Multiple Choice, Single Answer"],
      lmcsaObtain: obtainMark["Listening: Multiple Choice, Single Answer"],
      hiwTotal: totalMark["Highlight Incorrect Words"],
      hiwObtain: obtainMark["Highlight Incorrect Words"],
      fibTotal: totalMark["Fill in the Blanks"],
      fibObtain: obtainMark["Fill in the Blanks"],
      wfdTotal: totalMark["Write from Dictation"],
      wfdObtain: obtainMark["Write from Dictation"],
      hcsTotal: totalMark["Highlight Correct Summary"],
      hcsObtain: obtainMark["Highlight Correct Summary"],
      smwTotal: totalMark["Select Missing Word"],
      smwObtain: obtainMark["Select Missing Word"],
    });
  };

  const calculateTotalScores = () => {
    const speakingScore = calculateSpeakingScore();
    const writingScore = calculateWritingScore();
    const readingScore = calculateReadingScore();
    const listeningScore = calculateListeningScore();

    let overall =
      (speakingScore + writingScore + readingScore + listeningScore) / 4;

    if (overall > 12) {
      overall -= overall > 40 ? 2 : 1;
    }

    setScores({
      overall: overall > 10 ? parseInt(overall) : 10,
      speaking: speakingScore,
      writing: writingScore,
      reading: readingScore,
      listening: listeningScore,
    });
  };

  const calculateSpeakingScore = () => {
    let tempTotal = 0;
    TestsNames["Speaking"].forEach((test) => {
      let tmp = parseFloat(getSpeakingObtained(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    return tempTotal;
  };

  const calculateWritingScore = () => {
    let tempTotal = 0;
    TestsNames["Writing"].forEach((test) => {
      let tmp = parseFloat(getWritingObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    return tempTotal;
  };

  const calculateReadingScore = () => {
    let tempTotal = 0;
    TestsNames["Reading"].forEach((test) => {
      let tmp = parseFloat(getReadingObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    return tempTotal;
  };

  const calculateListeningScore = () => {
    let tempTotal = 0;
    TestsNames["Listening"].forEach((test) => {
      let tmp = parseFloat(getListenObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    return tempTotal;
  };

  const getSpeakingTotal = (keyName = "Read Aloud") => {
    const keyPercentages = {
      "Read Aloud": 33,
      "Repeat Sentence": 30,
      "Describe Image": 22,
      "Re-tell Lecture": 10,
      "Answer Short Question": 5,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
  };

  const getSpeakingObtained = (keyName = "Read Aloud") => {
    let ra =
      (categoryScores["raObtain"] / categoryScores["raTotal"]) *
      getSpeakingTotal("Read Aloud");
    let rs =
      (categoryScores["rsObtain"] / categoryScores["rsTotal"]) *
      getSpeakingTotal("Repeat Sentence");
    let di =
      (categoryScores["diObtain"] / categoryScores["diTotal"]) *
      getSpeakingTotal("Describe Image");
    let rl =
      (categoryScores["rlObtain"] / categoryScores["rlTotal"]) *
      getSpeakingTotal("Re-tell Lecture");
    let asq =
      (categoryScores["asqObtain"] / categoryScores["asqTotal"]) *
      getSpeakingTotal("Answer Short Question");
    const key = {
      "Read Aloud": ra,
      "Repeat Sentence": rs,
      "Describe Image": di,
      "Re-tell Lecture": rl,
      "Answer Short Question": asq,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getWritingTotal = (keyName) => {
    const keyPercentages = {
      "Summarize Written Text": 6.0,
      "Write Essay": 17.0,
      "Reading & Writing: Fill in the Blanks": 25.0,
      "Summarize Spoken Text": 6.0,
      "Write from Dictation": 28.0,
      "Fill in the Blanks": 18.0,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
  };

  const getWritingObtain = (keyName) => {
    let swt =
      (categoryScores["swtObtain"] / categoryScores["swtTotal"]) *
      getWritingTotal("Summarize Written Text");
    let we =
      (categoryScores["weObtain"] / categoryScores["weTotal"]) *
      getWritingTotal("Write Essay");
    let rwfib =
      (categoryScores["rwfibObtain"] / categoryScores["rwfibTotal"]) *
      getWritingTotal("Reading & Writing: Fill in the Blanks");
    let sst =
      (categoryScores["sstObtain"] / categoryScores["sstTotal"]) *
      getWritingTotal("Summarize Spoken Text");
    let wfd =
      (categoryScores["wfdObtain"] / categoryScores["wfdTotal"]) *
      getWritingTotal("Write from Dictation");
    let fib =
      (categoryScores["fibObtain"] / categoryScores["fibTotal"]) *
      getWritingTotal("Fill in the Blanks");
    const key = {
      "Summarize Written Text": swt,
      "Write Essay": we,
      "Reading & Writing: Fill in the Blanks": rwfib,
      "Summarize Spoken Text": sst,
      "Write from Dictation": wfd,
      "Fill in the Blanks": fib,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getReadingTotal = (keyName) => {
    const keyPercentages = {
      "Read Aloud": 27,
      "Summarize Written Text": 4,
      "Reading & Writing: Fill in the Blanks": 29,
      "Reading: Fill in the Blanks": 20,
      "Multiple Choice, Multiple Answers": 2,
      "Multiple Choice, Single Answer": 1,
      "Re-order Paragraphs": 7,
      "Highlight Correct Summary": 1,
      "Highlight Incorrect Words": 9,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
  };

  const getReadingObtain = (keyName) => {
    let ra =
      (categoryScores["raObtain"] / categoryScores["raTotal"]) *
      getReadingTotal("Read Aloud");
    let swt =
      (categoryScores["swtObtain"] / categoryScores["swtTotal"]) *
      getReadingTotal("Summarize Written Text");
    let rwfib =
      (categoryScores["rwfibObtain"] / categoryScores["rwfibTotal"]) *
      getReadingTotal("Reading & Writing: Fill in the Blanks");
    let rfib =
      (categoryScores["rfibObtain"] / categoryScores["rfibTotal"]) *
      getReadingTotal("Reading: Fill in the Blanks");
    let mcma =
      (categoryScores["mcmaObtain"] / categoryScores["mcmaTotal"]) *
      getReadingTotal("Multiple Choice, Multiple Answers");
    let mcsa =
      (categoryScores["mcsaObtain"] / categoryScores["mcsaTotal"]) *
      getReadingTotal("Multiple Choice, Single Answer");
    let rop =
      (categoryScores["ropObtain"] / categoryScores["ropTotal"]) *
      getReadingTotal("Re-order Paragraphs");
    let hcs =
      (categoryScores["hcsObtain"] / categoryScores["hcsTotal"]) *
      getReadingTotal("Highlight Correct Summary");
    let hiw =
      (categoryScores["hiwObtain"] / categoryScores["hiwTotal"]) *
      getReadingTotal("Highlight Incorrect Words");
    const key = {
      "Read Aloud": ra,
      "Summarize Written Text": swt,
      "Reading & Writing: Fill in the Blanks": rwfib,
      "Reading: Fill in the Blanks": rfib,
      "Multiple Choice, Multiple Answers": mcma,
      "Multiple Choice, Single Answer": mcsa,
      "Re-order Paragraphs": rop,
      "Highlight Correct Summary": hcs,
      "Highlight Incorrect Words": hiw,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getListenTotal = (keyName) => {
    const keyPercentages = {
      "Summarize Spoken Text": 6,
      "Multiple Choice, Multiple Answers": 2,
      "Multiple Choice, Single Answer": 1,
      "Highlight Incorrect Words": 16,
      "Fill in the Blanks": 12,
      "Write from Dictation": 25,
      "Highlight Correct Summary": 1,
      "Select Missing Word": 1,
      "Repeat Sentence": 23,
      "Re-tell Lecture": 9,
      "Answer Short Question": 4,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
  };

  const getListenObtain = (keyName) => {
    let sst =
      (categoryScores["sstObtain"] / categoryScores["sstTotal"]) *
      getListenTotal("Summarize Spoken Text");
    let mcma =
      (categoryScores["lmcmaObtain"] / categoryScores["lmcmaTotal"]) *
      getListenTotal("Multiple Choice, Multiple Answers");
    let mcsa =
      (categoryScores["lmcsaObtain"] / categoryScores["lmcsaTotal"]) *
      getListenTotal("Multiple Choice, Single Answer");
    let hiw =
      (categoryScores["hiwObtain"] / categoryScores["hiwTotal"]) *
      getListenTotal("Highlight Incorrect Words");
    let fib =
      (categoryScores["fibObtain"] / categoryScores["fibTotal"]) *
      getListenTotal("Fill in the Blanks");
    let wfd =
      (categoryScores["wfdObtain"] / categoryScores["wfdTotal"]) *
      getListenTotal("Write from Dictation");
    let hcs =
      (categoryScores["hcsObtain"] / categoryScores["hcsTotal"]) *
      getListenTotal("Highlight Correct Summary");
    let smw =
      (categoryScores["smwObtain"] / categoryScores["smwTotal"]) *
      getListenTotal("Select Missing Word");
    let rs =
      (categoryScores["rsObtain"] / categoryScores["rsTotal"]) *
      getListenTotal("Repeat Sentence");
    let rl =
      (categoryScores["rlObtain"] / categoryScores["rlTotal"]) *
      getListenTotal("Re-tell Lecture");
    let asq =
      (categoryScores["asqObtain"] / categoryScores["asqTotal"]) *
      getListenTotal("Answer Short Question");
    const key = {
      "Summarize Spoken Text": sst,
      "Multiple Choice, Multiple Answers": mcma,
      "Multiple Choice, Single Answer": mcsa,
      "Highlight Incorrect Words": hiw,
      "Fill in the Blanks": fib,
      "Write from Dictation": wfd,
      "Highlight Correct Summary": hcs,
      "Select Missing Word": smw,
      "Repeat Sentence": rs,
      "Re-tell Lecture": rl,
      "Answer Short Question": asq,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  useEffect(() => {
    if (scores.overall != null) {
      setFeedback({
        overall: getFeedback(
          "overall",
          scores.overall,
          scores.overall,
          targetRange
        ),
        reading: getFeedback(
          "reading",
          scores.overall,
          scores.reading,
          targetRange
        ),
        writing: getFeedback(
          "writing",
          scores.overall,
          scores.writing,
          targetRange
        ),
        listening: getFeedback(
          "listening",
          scores.overall,
          scores.listening,
          targetRange
        ),
        speaking: getFeedback(
          "speaking",
          scores.overall,
          scores.speaking,
          targetRange
        ),
      });
    }
  }, [scores, targetRange]);

  useEffect(() => {
    if (categoryScores.raTotal !== 0) {
      calculateTotalScores();
    }
  }, [categoryScores]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("targetcard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {loading && <LoadingModal />}
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "1.5rem 2% 2rem" : "6.5rem 3% 2rem",
          gap: "1.25rem",
        }}
      >
        <FeedbackHeader>
          <FeedbackHeaderTitle>AI Score Report Analysis</FeedbackHeaderTitle>
          {/* <FeedbackHeaderText>
            Your Target Score {mockTestType}: {startValue}
          </FeedbackHeaderText> */}
          {mockTestType === "Reading" && readingTarget && (
            <FeedbackHeaderText>
              Target Score Reading: {readingTarget}
            </FeedbackHeaderText>
          )}
          {mockTestType === "Writing" && writingTarget && (
            <FeedbackHeaderText>
              Target Score Writing: {writingTarget}
            </FeedbackHeaderText>
          )}
          {mockTestType === "Speaking" && speakingTarget && (
            <FeedbackHeaderText>
              Target Score Speaking: {speakingTarget}
            </FeedbackHeaderText>
          )}
          {mockTestType === "Listening" && listeningTarget && (
            <FeedbackHeaderText>
              Target Score Listening: {listeningTarget}
            </FeedbackHeaderText>
          )}
          <FeedbackHeaderText>Report Analyzed</FeedbackHeaderText>
        </FeedbackHeader>
        {/* <FeedbackCard score={scores.overall} text={feedback.overall} />
        <FeedbackCard
          title="Reading"
          bg="#AD826E"
          score={scores.reading}
          text={feedback.reading}
        />
        <FeedbackCard
          title="Writing"
          bg="#FF5D5D"
          score={scores.writing}
          text={feedback.writing}
        />
        <FeedbackCard
          title="Listening"
          bg="#868EAF"
          score={scores.listening}
          text={feedback.listening}
        />
        <FeedbackCard
          title="Speaking"
          bg="#49D7F2"
          score={scores.speaking}
          text={feedback.speaking}
        /> */}
        <>
          {mockTestType === "Reading" && (
            <FeedbackCard
              title="Reading"
              bg="#AD826E"
              score={scores.reading}
              text={feedback.reading}
            />
          )}
          {mockTestType === "Writing" && (
            <FeedbackCard
              title="Writing"
              bg="#FF5D5D"
              score={scores.writing}
              text={feedback.writing}
            />
          )}
          {mockTestType === "Listening" && (
            <FeedbackCard
              title="Listening"
              bg="#868EAF"
              score={scores.listening}
              text={feedback.listening}
            />
          )}
          {mockTestType === "Speaking" && (
            <FeedbackCard
              title="Speaking"
              bg="#49D7F2"
              score={scores.speaking}
              text={feedback.speaking}
            />
          )}
        </>

        {open && (
          <Modal isOpen={open} style={modalStyle}>
            <SetTargetPopup
              close={setOpen}
              startValue={startValue}
              endValue={endValue}
              handleSetTarget={handleSetTarget}
            />
          </Modal>
        )}
      </FlexDiv>
    </>
  );
};

export default FeedBackSectional;
