import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const AvgScoreCard = ({ borderRadius, backgroundColor, text, score, flex, marginRight }) => {
  const isLaptop = useMediaQuery('(max-width:1320px)')
  const isSLaptop = useMediaQuery('(max-width:1000px)')
  const isTab = useMediaQuery('(max-width:920px)')
  const isSTab = useMediaQuery('(max-width:700px)')
  const isDesktopTwo = useMediaQuery('(max-width:1500px)')
  const isDesktop = useMediaQuery('(max-width:1500px)')

  return (
    <Card
      sx={{
        display: "flex",
        // width: "100%", //100%
        height: "117px",
        // padding: isDesktop?isDesktopTwo?(isLaptop?
        //                   (isSLaptop?
        //                     (isTab?
        //                       (isSTab?'26px 48%':'26px 8.7%')
        //                               :"26px 8.8%"):"26px 8.2%")
        //                               :"26px 8.45%"):"3rem 8.2%":"2rem 8.6%",
        padding:isSTab?'0px 50%':'0.4% 9.9%',
        flexDirection: "column",
        alignItems: "center",
        justifyContent:'center',
        gap: "10px",
        borderRadius: isSTab?"16px":borderRadius || "16px",
        background: backgroundColor || "#996CFE",
        flex: flex || "0 0 0",
        marginRight:marginRight||'0px',
        boxShadow:'none'
      }}
    >
      <Typography
        sx={{
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Noto Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "16px",
          letterSpacing: "-0.144px",
        }}
      >
        {text}
      </Typography>

      <Typography
        sx={{
          color: "#FFF",
          textAlign: "center",
          fontFamily: "Noto Sans",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "38.871px",
        }}
      >
        {score}
      </Typography>
    </Card>
  );
};

export default AvgScoreCard;
