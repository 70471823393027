import React, { useState, useEffect } from "react";
import { AnalyticDivOne, MainDiv } from "./style";
import { useMediaQuery } from "@mui/material";
import AnalyticsCard2 from "../Home/AnalyticsCard2";
import { AnalyticsCard2Data } from "../Home/data";
import { FlexDiv } from "../../assets/styles/style";
import TodayTaskImg1 from "../../assets/todaytaskcard/todaytaskimg1.svg";
import TodayTaskImg2 from "../../assets/todaytaskcard/todaytaskimg2.svg";
import TodayTaskImg3 from "../../assets/todaytaskcard/todaytaskimg3.svg";
import TodayTaskImg4 from "../../assets/todaytaskcard/todaytaskimg4.svg";

const TestsNames = {
  Speaking: [
    "Read Aloud",
    "Repeat Sentence",
    "Describe Image",
    "Re-tell Lecture",
    "Answer Short Question",
  ],
  Writing: [
    "Summarize Written Text",
    "Write Essay",
    "Reading & Writing: Fill in the Blanks",
    "Summarize Spoken Text",
    "Fill in the Blanks",
    "Write from Dictation",
  ],
  Reading: [
    "Read Aloud",
    "Summarize Written Text",
    "Reading & Writing: Fill in the Blanks",
    "Multiple Choice, Multiple Answers",
    "Re-order Paragraphs",
    "Reading: Fill in the Blanks",
    "Multiple Choice, Single Answer",
    "Highlight Correct Summary",
    "Highlight Incorrect Words",
  ],
  Listening: [
    "Repeat Sentence",
    "Re-Tell Lecture",
    "Answer Short Question",
    "Summarize Spoken Text",
    "Multiple Choice, Multiple Answers",
    "Fill in the Blanks",
    "Highlight Correct Summary",
    "Highlight Incorrect Words",
    "Multiple Choice, Single Answer",
    "Select Missing Word",
    "Write from Dictation",
  ],
};

const AnalyticsForMtScoreSectional = ({
  counts = null,
  mockTestType,
  speakingTotScore,
  writingTotScore,
  readingTotScore,
  listeningTotScore,
  seen = true,
}) => {
  const isTab = useMediaQuery("(max-width:700px)");
  const [scores, setScores] = useState({
    raTotal: 0,
    raObtain: 0,
    rsTotal: 0,
    rsObtain: 0,
    diTotal: 0,
    diObtain: 0,
    rlTotal: 0,
    rlObtain: 0,
    asqTotal: 0,
    asqObtain: 0,
    swtTotal: 0,
    swtObtain: 0,
    weTotal: 0,
    weObtain: 0,
    rwfibTotal: 0,
    rwfibObtain: 0,
    rfibTotal: 0,
    rfibObtain: 0,
    mcmaTotal: 0,
    mcmaObtain: 0,
    mcsaTotal: 0,
    mcsaObtain: 0,
    ropTotal: 0,
    ropObtain: 0,
    sstTotal: 0,
    sstObtain: 0,
    lmcmaTotal: 0,
    lmcmaObtain: 0,
    lmcsaTotal: 0,
    lmcsaObtain: 0,
    hiwTotal: 0,
    hiwObtain: 0,
    fibTotal: 0,
    fibObtain: 0,
    wfdTotal: 0,
    wfdObtain: 0,
    hcsTotal: 0,
    hcsObtain: 0,
    smwTotal: 0,
    smwObtain: 0,
  });

  const giveScores = () => {
    let totalMark = {
      "Read Aloud": 0,
      "Repeat Sentence": 0,
      "Describe Image": 0,
      "Re-tell Lecture": 0,
      "Answer Short Question": 0,
      "Summarize Written Text": 0,
      "Write Essay": 0,
      "Reading & Writing: Fill in the Blanks": 0,
      "Reading: Fill in the Blanks": 0,
      "Multiple Choice, Multiple Answers": 0,
      "Multiple Choice, Single Answer": 0,
      "Re-order Paragraphs": 0,
      "Summarize Spoken Text": 0,
      "Listening: Multiple Choice, Multiple Answers": 0,
      "Listening: Multiple Choice, Single Answer": 0,
      "Highlight Incorrect Words": 0,
      "Fill in the Blanks": 0,
      "Write from Dictation": 0,
      "Highlight Correct Summary": 0,
      "Select Missing Word": 0,
    };
    let obtainMark = {
      "Read Aloud": 0,
      "Repeat Sentence": 0,
      "Describe Image": 0,
      "Re-tell Lecture": 0,
      "Answer Short Question": 0,
      "Summarize Written Text": 0,
      "Write Essay": 0,
      "Reading & Writing: Fill in the Blanks": 0,
      "Reading: Fill in the Blanks": 0,
      "Multiple Choice, Multiple Answers": 0,
      "Multiple Choice, Single Answer": 0,
      "Re-order Paragraphs": 0,
      "Summarize Spoken Text": 0,
      "Listening: Multiple Choice, Multiple Answers": 0,
      "Listening: Multiple Choice, Single Answer": 0,
      "Highlight Incorrect Words": 0,
      "Fill in the Blanks": 0,
      "Write from Dictation": 0,
      "Highlight Correct Summary": 0,
      "Select Missing Word": 0,
    };

    counts.forEach((test) => {
      test.forEach((result) => {
        if (result["UserResponse"]["SubCategory"] === "Read Aloud") {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Read Aloud"] += 15;
          let pronounScore = tmp.pronunciation_score
            ? tmp.pronunciation_score
            : tmp.pronounciation_score;
          let obtain = tmp.content_score + tmp.fluency_score + pronounScore;
          obtainMark["Read Aloud"] += obtain;
        } else if (
          result["UserResponse"]["SubCategory"] === "Repeat Sentence"
        ) {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Repeat Sentence"] += 13;
          let obtain =
            tmp.content_score + tmp.fluency_score + tmp.pronunciation_score;
          obtainMark["Repeat Sentence"] += obtain;
        } else if (result["UserResponse"]["SubCategory"] === "Describe Image") {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Describe Image"] += 15;
          let obtain =
            tmp.content_score + tmp.fluency_score + tmp.pronounciation_score;
          obtainMark["Describe Image"] += obtain;
        } else if (
          result["UserResponse"]["SubCategory"] === "Re-tell Lecture"
        ) {
          //  console.log('AI Response for Describe Image:', result['UserResponse']['AI_response']);
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Re-tell Lecture"] += 15;
          let obtain =
            tmp.content_score + tmp.fluency_score + tmp.pronounciation_score;
          obtainMark["Re-tell Lecture"] += obtain;
        } else if (
          result["UserResponse"]["SubCategory"] === "Answer Short Question"
        ) {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Answer Short Question"] += 1;
          obtainMark["Answer Short Question"] += tmp["content_score"];
        } else if (
          result["UserResponse"]["SubCategory"] === "Summarize Written Text"
        ) {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Summarize Written Text"] += 8;
          obtainMark["Summarize Written Text"] += tmp.total_score;
        } else if (result["UserResponse"]["SubCategory"] === "Write Essay") {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Write Essay"] += 15;
          obtainMark["Write Essay"] += tmp.total_score;
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Reading & Writing: Fill in the Blanks"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Reading & Writing: Fill in the Blanks"] +=
            tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Reading & Writing: Fill in the Blanks"] += parseInt(
            score[0]
          );
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Reading: Fill in the Blanks"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Reading: Fill in the Blanks"] += tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Reading: Fill in the Blanks"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Multiple Choice, Multiple Answers"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Multiple Choice, Multiple Answers"] +=
            tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Multiple Choice, Multiple Answers"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Multiple Choice, Single Answer"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Multiple Choice, Single Answer"] += 1;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Multiple Choice, Single Answer"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] === "Re-order Paragraphs"
        ) {
          let tmp = result["UserResponse"]["submissionResult"];
          totalMark["Re-order Paragraphs"] += tmp.correctIndexes.length;
          obtainMark["Re-order Paragraphs"] += tmp.score;
        } else if (
          result["UserResponse"]["SubCategory"] === "Summarize Spoken Text"
        ) {
          let tmp = JSON.parse(result["UserResponse"]["AI_response"]);
          totalMark["Summarize Spoken Text"] += 10;
          obtainMark["Summarize Spoken Text"] += tmp.total_score;
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Listening: Multiple Choice, Multiple Answers"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Listening: Multiple Choice, Multiple Answers"] +=
            tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Listening: Multiple Choice, Multiple Answers"] +=
            parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] ===
          "Listening: Multiple Choice, Single Answer"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Listening: Multiple Choice, Single Answer"] += 1;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Listening: Multiple Choice, Single Answer"] += parseInt(
            score[0]
          );
        } else if (
          result["UserResponse"]["SubCategory"] === "Highlight Incorrect Words"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Highlight Incorrect Words"] += tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Highlight Incorrect Words"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] === "Fill in the Blanks"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Fill in the Blanks"] += tmp.correctAnswers.length;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Fill in the Blanks"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] === "Write from Dictation"
        ) {
          // let tmp = JSON.parse(result['UserResponse']['AI_response']);
          // totalMark['Write from Dictation'] += tmp.total_score;
          // obtainMark['Write from Dictation'] += tmp.writing_score;
          if (result["UserResponse"] && result["UserResponse"]["AI_response"]) {
            let tmp;
            try {
              tmp = JSON.parse(result["UserResponse"]["AI_response"]);
              totalMark["Write from Dictation"] += tmp.total_score;
              obtainMark["Write from Dictation"] += tmp.writing_score;
            } catch (e) {
              console.error("Error parsing AI_response:", e);
              tmp = {};
            }
          }
        } else if (
          result["UserResponse"]["SubCategory"] === "Highlight Correct Summary"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Highlight Correct Summary"] += 1;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Highlight Correct Summary"] += parseInt(score[0]);
        } else if (
          result["UserResponse"]["SubCategory"] === "Select Missing Word"
        ) {
          let tmp = result["UserResponse"];
          totalMark["Select Missing Word"] += 1;
          let score = tmp.enableSkillsData[0]["score"];
          obtainMark["Select Missing Word"] += parseInt(score[0]);
        }
      });
    });

    setScores({
      raTotal: totalMark["Read Aloud"],
      raObtain: obtainMark["Read Aloud"],
      rsTotal: totalMark["Repeat Sentence"],
      rsObtain: obtainMark["Repeat Sentence"],
      diTotal: totalMark["Describe Image"],
      diObtain: obtainMark["Describe Image"],
      rlTotal: totalMark["Re-tell Lecture"],
      rlObtain: obtainMark["Re-tell Lecture"],
      asqTotal: totalMark["Answer Short Question"],
      asqObtain: obtainMark["Answer Short Question"],
      swtTotal: totalMark["Summarize Written Text"],
      swtObtain: obtainMark["Summarize Written Text"],
      weTotal: totalMark["Write Essay"],
      weObtain: obtainMark["Write Essay"],
      rwfibTotal: totalMark["Reading & Writing: Fill in the Blanks"],
      rwfibObtain: obtainMark["Reading & Writing: Fill in the Blanks"],
      mcmaTotal: totalMark["Multiple Choice, Multiple Answers"],
      mcmaObtain: obtainMark["Multiple Choice, Multiple Answers"],
      mcsaTotal: totalMark["Multiple Choice, Single Answer"],
      mcsaObtain: totalMark["Multiple Choice, Single Answer"],
      ropTotal: totalMark["Re-order Paragraphs"],
      ropObtain: obtainMark["Re-order Paragraphs"],
      sstTotal: totalMark["Summarize Spoken Text"],
      sstObtain: obtainMark["Summarize Spoken Text"],
      lmcmaTotal: totalMark["Listening: Multiple Choice, Multiple Answers"],
      lmcmaObtain: obtainMark["Listening: Multiple Choice, Multiple Answers"],
      lmcsaTotal: totalMark["Listening: Multiple Choice, Single Answer"],
      lmcsaObtain: obtainMark["Listening: Multiple Choice, Single Answer"],
      hiwTotal: totalMark["Highlight Incorrect Words"],
      hiwObtain: obtainMark["Highlight Incorrect Words"],
      fibTotal: totalMark["Fill in the Blanks"],
      fibObtain: obtainMark["Fill in the Blanks"],
      wfdTotal: totalMark["Write from Dictation"],
      wfdObtain: obtainMark["Write from Dictation"],
      hcsTotal: totalMark["Highlight Correct Summary"],
      hcsObtain: obtainMark["Highlight Correct Summary"],
      smwTotal: totalMark["Select Missing Word"],
      smwObtain: obtainMark["Select Missing Word"],
      rfibTotal: totalMark["Reading: Fill in the Blanks"],
      rfibObtain: obtainMark["Reading: Fill in the Blanks"],
    });
  };

  const getSpeakingTotal = (keyName = "Read Aloud") => {
    const keyPercentages = {
      "Read Aloud": 33,
      "Repeat Sentence": 30,
      "Describe Image": 22,
      "Re-tell Lecture": 10,
      "Answer Short Question": 5,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
  };

  const getSpeakingObtained = (keyName = "Read Aloud") => {
    // console.log('Describe Image Total:', scores['rlTotal']);
    // console.log('Describe Image Obtain:', scores['rlObtain']);
    let ra =
      (scores["raObtain"] / scores["raTotal"]) * getSpeakingTotal(keyName);
    let rs =
      (scores["rsObtain"] / scores["rsTotal"]) * getSpeakingTotal(keyName);
    let di =
      (scores["diObtain"] / scores["diTotal"]) * getSpeakingTotal(keyName);
    let rl =
      (scores["rlObtain"] / scores["rlTotal"]) * getSpeakingTotal(keyName);
    let asq =
      (scores["asqObtain"] / scores["asqTotal"]) * getSpeakingTotal(keyName);
    const key = {
      "Read Aloud": ra,
      "Repeat Sentence": rs,
      "Describe Image": di,
      "Re-tell Lecture": rl,
      "Answer Short Question": asq,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getWritingTotal = (keyName) => {
    const keyPercentages = {
      "Summarize Written Text": 6.0,
      "Write Essay": 17.0,
      "Reading & Writing: Fill in the Blanks": 25.0,
      "Summarize Spoken Text": 6.0,
      "Write from Dictation": 28.0,
      "Fill in the Blanks": 18.0,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
    // let tot = scores['swtTotal'] + scores['weTotal'] + scores['rwfibTotal'] + scores['sstTotal'] + scores['wfdTotal'] + scores['fibTotal'];
    // let swt = scores['swtTotal']/tot * 6;
    // let we = scores['weTotal']/tot * 17;
    // let rwfib = scores['rwfibTotal']/tot * 25;
    // let sst = scores['sstTotal']/tot * 6;
    // let wfd = scores['wfdTotal']/tot * 28;
    // let fib = scores['fibTotal']/tot * 18;
    // let percentTot = (swt+we+rwfib+sst+wfd+fib);
    // if(percentTot > 0){
    //   percentTot = 90/percentTot;
    // }
    // const key = {
    //   'Summarize Written Text': swt,
    //   'Write Essay': we,
    //   'Reading & Writing: Fill in the Blanks': rwfib,
    //   'Summarize Spoken Text': sst,
    //   'Write from Dictation': wfd,
    //   'Fill in the Blanks': fib
    // }
    // let res = key[keyName]* percentTot;
    // return (typeof(res) === 'number')?res.toFixed(1):res;
  };

  const getWritingObtain = (keyName) => {
    let swt =
      (scores["swtObtain"] / scores["swtTotal"]) * getWritingTotal(keyName);
    let we =
      (scores["weObtain"] / scores["weTotal"]) * getWritingTotal(keyName);
    let rwfib =
      (scores["rwfibObtain"] / scores["rwfibTotal"]) * getWritingTotal(keyName);
    let sst =
      (scores["sstObtain"] / scores["sstTotal"]) * getWritingTotal(keyName);
    let wfd =
      (scores["wfdObtain"] / scores["wfdTotal"]) * getWritingTotal(keyName);
    let fib =
      (scores["fibObtain"] / scores["fibTotal"]) * getWritingTotal(keyName);
    const key = {
      "Summarize Written Text": swt,
      "Write Essay": we,
      "Reading & Writing: Fill in the Blanks": rwfib,
      "Summarize Spoken Text": sst,
      "Write from Dictation": wfd,
      "Fill in the Blanks": fib,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getReadingTotal = (keyName) => {
    const keyPercentages = {
      "Read Aloud": 27,
      "Summarize Written Text": 4,
      "Reading & Writing: Fill in the Blanks": 29,
      "Reading: Fill in the Blanks": 20,
      "Multiple Choice, Multiple Answers": 2,
      "Multiple Choice, Single Answer": 1,
      "Re-order Paragraphs": 7,
      "Highlight Correct Summary": 1,
      "Highlight Incorrect Words": 9,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
    // let tot = scores['raTotal'] + scores['swtTotal'] + scores['rwfibTotal'] + scores['rfibTotal'] + scores['mcmaTotal'] + scores['ropTotal'] + scores['mcsaTotal'] + scores['hcsTotal'] + scores['hiwTotal'];
    // let ra = scores['raTotal']/tot * 27;
    // let swt = scores['swtTotal']/tot * 4;
    // let fibrw = scores['rwfibTotal']/tot * 29;
    // let rfib = scores['rfibTotal']/tot * 20;
    // let mcma = scores['mcmaTotal']/tot * 2;
    // let mcsa = scores['mcsaTotal']/tot * 1;
    // let rop = scores['ropTotal']/tot * 7;
    // let hcs = scores['hcsTotal']/tot * 1;
    // let hiw = scores['hiwTotal']/tot * 9;

    // let percentTot = (ra+swt+fibrw+rfib+mcma+mcsa+rop+hcs+hiw);
    // if(percentTot > 0){
    //   percentTot = 90/percentTot;
    // }
    // const key = {
    //   'Read Aloud': ra,
    //   'Summarize Written Text': swt,
    //   'Reading & Writing: Fill in the Blanks': fibrw,
    //   'Reading: Fill in the Blanks': rfib,
    //   'Multiple Choice, Multiple Answers': mcma,
    //   'Multiple Choice, Single Answer':mcsa,
    //   'Re-order Paragraphs': rop,
    //   'Highlight Correct Summary': hcs,
    //   'Highlight Incorrect Words': hiw
    // }
    // let res = key[keyName]* percentTot;
    // return (typeof(res) === 'number')?res.toFixed(1):res;
  };

  const getReadingObtain = (keyName) => {
    let total = getReadingTotal(keyName);
    let ra = (scores["raObtain"] / scores["raTotal"]) * total;
    let swt = (scores["swtObtain"] / scores["swtTotal"]) * total;
    let fibrw = (scores["rwfibObtain"] / scores["rwfibTotal"]) * total;
    let rfib = (scores["rfibObtain"] / scores["rfibTotal"]) * total;
    let mcma = (scores["mcmaObtain"] / scores["mcmaTotal"]) * total;
    let mcsa = (scores["mcsaObtain"] / scores["mcsaTotal"]) * total;
    let rop = (scores["ropObtain"] / scores["ropTotal"]) * total;
    let hcs = (scores["hcsObtain"] / scores["hcsTotal"]) * total;
    let hiw = (scores["hiwObtain"] / scores["hiwTotal"]) * total;
    const key = {
      "Read Aloud": ra,
      "Summarize Written Text": swt,
      "Reading & Writing: Fill in the Blanks": fibrw,
      "Reading: Fill in the Blanks": rfib,
      "Multiple Choice, Multiple Answers": mcma,
      "Multiple Choice, Single Answer": mcsa,
      "Re-order Paragraphs": rop,
      "Highlight Correct Summary": hcs,
      "Highlight Incorrect Words": hiw,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const getListenTotal = (keyName) => {
    const keyPercentages = {
      "Summarize Spoken Text": 6,
      "Multiple Choice, Multiple Answers": 2,
      "Multiple Choice, Single Answer": 1,
      "Highlight Incorrect Words": 16,
      "Fill in the Blanks": 12,
      "Write from Dictation": 25,
      "Highlight Correct Summary": 1,
      "Select Missing Word": 1,
      "Repeat Sentence": 23,
      "Re-Tell Lecture": 9,
      "Answer Short Question": 4,
    };
    return (keyPercentages[keyName] * 0.9).toFixed(1);
    // let tot = scores['sstTotal'] + scores['lmcmaTotal'] + scores['lmcsaTotal'] + scores['hiwTotal'] + scores['fibTotal'] + scores['wfdTotal'] + scores['hcsTotal'] + scores['smwTotal'] + scores['rsTotal'] + scores['rlTotal'] + scores['asqTotal'];
    // let sst = scores['sstTotal']/tot * 6;
    // let mcma = scores['lmcmaTotal']/tot * 2;
    // let mcsa = scores['lmcsaTotal']/tot * 1;
    // let hiw  = scores['hiwTotal']/tot * 16;
    // let fib = scores['fibTotal']/tot * 12;
    // let wfd = scores['wfdTotal']/tot * 25;
    // let hcs  = scores['hcsTotal']/tot * 1;
    // let smw  = scores['smwTotal']/tot * 1;
    // let rs  = scores['rsTotal']/tot * 1;
    // let rl  = scores['rlTotal']/tot * 1;
    // let asq  = scores['asqTotal']/tot * 1;
    // let percentTot = (sst + mcma + mcsa + hiw + fib + wfd + hcs + smw + rs + rl + asq);
    // if(percentTot > 0){
    //   percentTot = 90/percentTot;
    // }
    // const key = {
    //   'Summarize Spoken Text': sst,
    //    'Multiple Choice, Multiple Answers': mcma,
    //    'Multiple Choice, Single Answer': mcsa,
    //    'Highlight Incorrect Words': hiw,
    //    'Fill in the Blanks': fib,
    //    'Write from Dictation': wfd,
    //    'Highlight Correct Summary': hcs,
    //    'Select Missing Word': smw,
    //    'Repeat Sentence': rs,
    //    'Re-Tell Lecture': rl,
    //    'Answer Short Question': asq
    // }
    // let res = key[keyName]* percentTot;
    // return (typeof(res) === 'number')?res.toFixed(1):res;
  };

  const getListenObtain = (keyName) => {
    let total = getListenTotal(keyName);
    let sst = (scores["sstObtain"] / scores["sstTotal"]) * total;
    let mcma = (scores["lmcmaObtain"] / scores["lmcmaTotal"]) * total;
    let mcsa = (scores["lmcsaObtain"] / scores["lmcsaTotal"]) * total;
    let hiw = (scores["hiwObtain"] / scores["hiwTotal"]) * total;
    let fib = (scores["fibObtain"] / scores["fibTotal"]) * total;
    let wfd = (scores["wfdObtain"] / scores["wfdTotal"]) * total;
    let hcs = (scores["hcsObtain"] / scores["hcsTotal"]) * total;
    let smw = (scores["smwObtain"] / scores["smwTotal"]) * total;
    let rs = (scores["rsObtain"] / scores["rsTotal"]) * total;
    let rl = (scores["rlObtain"] / scores["rlTotal"]) * total;
    let asq = (scores["asqObtain"] / scores["asqTotal"]) * total;

    const key = {
      "Summarize Spoken Text": sst,
      "Multiple Choice, Multiple Answers": mcma,
      "Multiple Choice, Single Answer": mcsa,
      "Highlight Incorrect Words": hiw,
      "Fill in the Blanks": fib,
      "Write from Dictation": wfd,
      "Highlight Correct Summary": hcs,
      "Select Missing Word": smw,
      "Repeat Sentence": rs,
      "Re-Tell Lecture": rl,
      "Answer Short Question": asq,
    };
    let res = key[keyName];
    if (isNaN(res)) {
      res = 0;
    }
    return typeof res === "number" ? res.toFixed(1) : res;
  };

  const speakingDataAcademic = {
    id: 1,
    imageSrc: TodayTaskImg4,
    titleText: "Speaking",
    titleTextColor: "#49D7F2",
    marksTextColor: "#66E0F7CC",
    barBgColor: "rgba(73, 215, 242, 0.2)",
    categoriesData: [
      {
        CategoryName: "Read Aloud",
        TotalCount: counts ? getSpeakingTotal("Read Aloud") : 0,
        attemptedcount: counts ? getSpeakingObtained("Read Aloud") : 0,
        totalProgress: "67",
        chunkCount: "10",
      },
      {
        CategoryName: "Repeat Sentence",
        TotalCount: counts ? getSpeakingTotal("Repeat Sentence") : 0,
        attemptedcount: counts ? getSpeakingObtained("Repeat Sentence") : 0,
        totalProgress: "70",
        chunkCount: "10",
      },
      {
        CategoryName: "Describe Image",
        TotalCount: counts ? getSpeakingTotal("Describe Image") : 0,
        attemptedcount: counts ? getSpeakingObtained("Describe Image") : 0,
        totalProgress: "20",
        chunkCount: "10",
      },
      {
        CategoryName: "Re-Tell Lecture",
        TotalCount: counts ? getSpeakingTotal("Re-tell Lecture") : 0,
        attemptedcount: counts ? getSpeakingObtained("Re-tell Lecture") : 0,
        totalProgress: "50",
        chunkCount: "10",
      },
      {
        CategoryName: "Answer Short Question",
        TotalCount: counts ? getSpeakingTotal("Answer Short Question") : 0,
        attemptedcount: counts
          ? getSpeakingObtained("Answer Short Question")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
    ],
  };
  const writingDataAcademic = {
    //writing data for PTE Academic
    id: 3,
    imageSrc: TodayTaskImg3,
    titleText: "Writing",
    titleTextColor: "#FF5D5D",
    marksTextColor: "#FF5D5DCC",
    barBgColor: "rgba(255, 93, 93, 0.2)",
    categoriesData: [
      {
        CategoryName: "Summarize Written Text",
        TotalCount: counts ? getWritingTotal("Summarize Written Text") : 0,
        attemptedcount: counts ? getWritingObtain("Summarize Written Text") : 0,
        totalProgress: "50",
        chunkCount: "10",
      },
      {
        CategoryName: "Write Essay",
        TotalCount: counts ? getWritingTotal("Write Essay") : 0,
        attemptedcount: counts ? getWritingObtain("Write Essay") : 0,
        totalProgress: "50",
        chunkCount: "10",
      },
      {
        CategoryName: "Reading & Writing: Fill in the Blanks",
        TotalCount: counts
          ? getWritingTotal("Reading & Writing: Fill in the Blanks")
          : 0,
        attemptedcount: counts
          ? getWritingObtain("Reading & Writing: Fill in the Blanks")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Summarize Spoken Text",
        TotalCount: counts ? getWritingTotal("Summarize Spoken Text") : 0,
        attemptedcount: counts ? getWritingObtain("Summarize Spoken Text") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Fill in the Blanks",
        TotalCount: counts ? getWritingTotal("Fill in the Blanks") : 0,
        attemptedcount: counts ? getWritingObtain("Fill in the Blanks") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Write from Dictation",
        TotalCount: counts ? getWritingTotal("Write from Dictation") : 0,
        attemptedcount: counts ? getWritingObtain("Write from Dictation") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
    ],
  };
  const readingData = {
    id: 3,
    imageSrc: TodayTaskImg1,
    titleText: "Reading",
    titleTextColor: "#AD826E",
    marksTextColor: "#AD826ECC",
    barBgColor: "rgba(173, 130, 110, 0.2)",
    categoriesData: [
      {
        CategoryName: "Read Aloud",
        TotalCount: counts ? getReadingTotal("Read Aloud") : 0,
        attemptedcount: counts ? getReadingObtain("Read Aloud") : 0,
        totalProgress: "67",
        chunkCount: "10",
      },
      {
        CategoryName: "Summarize Written Text",
        TotalCount: counts ? getReadingTotal("Summarize Written Text") : 0,
        attemptedcount: counts ? getReadingObtain("Summarize Written Text") : 0,
        totalProgress: "50",
        chunkCount: "10",
      },
      {
        CategoryName: "Reading & Writing: Fill in the Blanks",
        TotalCount: counts
          ? getReadingTotal("Reading & Writing: Fill in the Blanks")
          : 0,
        attemptedcount: counts
          ? getReadingObtain("Reading & Writing: Fill in the Blanks")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Reading: Fill in the Blanks",
        TotalCount: counts ? getReadingTotal("Reading: Fill in the Blanks") : 0,
        attemptedcount: counts
          ? getReadingObtain("Reading: Fill in the Blanks")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Multiple Choice, Multiple Answers",
        TotalCount: counts
          ? getReadingTotal("Multiple Choice, Multiple Answers")
          : 0,
        attemptedcount: counts
          ? getReadingObtain("Multiple Choice, Multiple Answers")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Re-order Paragraphs",
        TotalCount: counts ? getReadingTotal("Re-order Paragraphs") : 0,
        attemptedcount: counts ? getReadingObtain("Re-order Paragraphs") : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Multiple Choice, Single Answer",
        TotalCount: counts
          ? getReadingTotal("Multiple Choice, Single Answer")
          : 0,
        attemptedcount: counts
          ? getReadingObtain("Multiple Choice, Single Answer")
          : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
      {
        CategoryName: "Highlight Correct Summary",
        TotalCount: counts ? getReadingTotal("Highlight Correct Summary") : 0,
        attemptedcount: counts
          ? getReadingObtain("Highlight Correct Summary")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Highlight Incorrect Words",
        TotalCount: counts ? getReadingTotal("Highlight Incorrect Words") : 0,
        attemptedcount: counts
          ? getReadingObtain("Highlight Incorrect Words")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
    ],
  };
  const listeningDataAcademic = {
    id: 2,
    imageSrc: TodayTaskImg2,
    titleText: "Listening",
    titleTextColor: "#868EAF",
    marksTextColor: "#868EAFCC",
    barBgColor: "rgba(134, 142, 175, 0.2)",
    categoriesData: [
      {
        CategoryName: "Summarize Spoken Text",
        TotalCount: counts ? getListenTotal("Summarize Spoken Text") : 0,
        attemptedcount: counts ? getListenObtain("Summarize Spoken Text") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Multiple Choice, Multiple Answers",
        TotalCount: counts
          ? getListenTotal("Multiple Choice, Multiple Answers")
          : 0,
        attemptedcount: counts
          ? getListenObtain("Multiple Choice, Multiple Answers")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Multiple Choice, Single Answer",
        TotalCount: counts
          ? getListenTotal("Multiple Choice, Single Answer")
          : 0,
        attemptedcount: counts
          ? getListenObtain("Multiple Choice, Single Answer")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Highlight Incorrect Words",
        TotalCount: counts ? getListenTotal("Highlight Incorrect Words") : 0,
        attemptedcount: counts
          ? getListenObtain("Highlight Incorrect Words")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Fill in the Blanks",
        TotalCount: counts ? getListenTotal("Fill in the Blanks") : 0,
        attemptedcount: counts ? getListenObtain("Fill in the Blanks") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Write from Dictation",
        TotalCount: counts ? getListenTotal("Write from Dictation") : 0,
        attemptedcount: counts ? getListenObtain("Write from Dictation") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Highlight Correct Summary",
        TotalCount: counts ? getListenTotal("Highlight Correct Summary") : 0,
        attemptedcount: counts
          ? getListenObtain("Highlight Correct Summary")
          : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Select Missing Word",
        TotalCount: counts ? getListenTotal("Select Missing Word") : 0,
        attemptedcount: counts ? getListenObtain("Select Missing Word") : 0,
        totalProgress: "40",
        chunkCount: "10",
      },
      {
        CategoryName: "Repeat Sentence",
        TotalCount: counts ? getListenTotal("Repeat Sentence") : 0,
        attemptedcount: counts ? getListenObtain("Repeat Sentence") : 0,
        totalProgress: "70",
        chunkCount: "10",
      },
      {
        CategoryName: "Re-Tell Lecture",
        TotalCount: counts ? getListenTotal("Re-Tell Lecture") : 0,
        attemptedcount: counts ? getListenObtain("Re-Tell Lecture") : 0,
        totalProgress: "50",
        chunkCount: "10",
      },
      {
        CategoryName: "Answer Short Question",
        TotalCount: counts ? getListenTotal("Answer Short Question") : 0,
        attemptedcount: counts ? getListenObtain("Answer Short Question") : 0,
        totalProgress: "80",
        chunkCount: "10",
      },
    ],
  };

  const getTotSpeakingScore = () => {
    let tempTotal = 0;
    TestsNames["Speaking"].forEach((test) => {
      let tmp = parseFloat(getSpeakingObtained(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    speakingTotScore(tempTotal);
  };

  const getTotReadingScore = () => {
    let tempTotal = 0;
    TestsNames["Reading"].forEach((test) => {
      let tmp = parseFloat(getReadingObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    readingTotScore(tempTotal);
  };

  const getTotListeningScore = () => {
    let tempTotal = 0;
    TestsNames["Listening"].forEach((test) => {
      let tmp = parseFloat(getListenObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    listeningTotScore(tempTotal);
  };

  const getTotWritingScore = () => {
    let tempTotal = 0;
    TestsNames["Writing"].forEach((test) => {
      let tmp = parseFloat(getWritingObtain(test));
      tempTotal += tmp;
    });
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal < 10 ? 10 : tempTotal;
    writingTotScore(tempTotal);
  };

  useEffect(() => {
    giveScores();
  }, []);

  useEffect(() => {
    getTotSpeakingScore();
    getTotReadingScore();
    getTotListeningScore();
    getTotWritingScore();
  }, [scores]);

  return seen ? (
    <FlexDiv
      style={{ width: "100%", flexDirection: "column", maxWidth: "1680px" }}
    >
      <MainDiv>
        {!isTab && (
          <>
            <FlexDiv
              style={{
                marginTop: "20px",
                width: "100%",
              }}
            >
              <FlexDiv style={{ width: "100%" }}>
                {/* speaking */}
                {mockTestType === "Speaking" && (
                  <AnalyticsCard2
                    key={AnalyticsCard2Data[0].id}
                    imageSrc={AnalyticsCard2Data[0].imageSrc}
                    titleText={AnalyticsCard2Data[0].titleText}
                    titleTextColor={AnalyticsCard2Data[0].titleTextColor}
                    marksTextColor={AnalyticsCard2Data[0].marksTextColor}
                    barBgColor={AnalyticsCard2Data[0].barBgColor}
                    categoriesData={speakingDataAcademic.categoriesData}
                    widthOne={"35px"}
                  />
                )}

                {/* Reading */}
                {mockTestType === "Reading" && (
                  <AnalyticsCard2
                    key={AnalyticsCard2Data[3].id}
                    imageSrc={AnalyticsCard2Data[3].imageSrc}
                    titleText={AnalyticsCard2Data[3].titleText}
                    titleTextColor={AnalyticsCard2Data[3].titleTextColor}
                    marksTextColor={AnalyticsCard2Data[3].marksTextColor}
                    barBgColor={AnalyticsCard2Data[3].barBgColor}
                    categoriesData={readingData.categoriesData}
                    widthOne={"35px"}
                  />
                )}
              </FlexDiv>
              <AnalyticDivOne>
                {mockTestType === "Writing" && (
                  <AnalyticsCard2
                    key={AnalyticsCard2Data[2].id}
                    imageSrc={AnalyticsCard2Data[2].imageSrc}
                    titleText={AnalyticsCard2Data[2].titleText}
                    titleTextColor={AnalyticsCard2Data[2].titleTextColor}
                    marksTextColor={AnalyticsCard2Data[2].marksTextColor}
                    barBgColor={AnalyticsCard2Data[2].barBgColor}
                    categoriesData={writingDataAcademic.categoriesData}
                    widthOne={"35px"}
                  />
                )}

                {/* Listening */}
                {mockTestType === "Listening" && (
                  <AnalyticsCard2
                    key={AnalyticsCard2Data[1].id}
                    imageSrc={AnalyticsCard2Data[1].imageSrc}
                    titleText={AnalyticsCard2Data[1].titleText}
                    titleTextColor={AnalyticsCard2Data[1].titleTextColor}
                    marksTextColor={AnalyticsCard2Data[1].marksTextColor}
                    barBgColor={AnalyticsCard2Data[1].barBgColor}
                    categoriesData={listeningDataAcademic.categoriesData}
                    widthOne={"35px"}
                  />
                )}
              </AnalyticDivOne>
            </FlexDiv>
          </>
        )}
      </MainDiv>
      {isTab && (
        <>
          <FlexDiv
            style={{
              marginTop: "20px",
              width: "90%",
            }}
          >
            {mockTestType === "Speaking" && (
              <AnalyticsCard2
                key={AnalyticsCard2Data[0].id}
                imageSrc={AnalyticsCard2Data[0].imageSrc}
                titleText={AnalyticsCard2Data[0].titleText}
                titleTextColor={AnalyticsCard2Data[0].titleTextColor}
                marksTextColor={AnalyticsCard2Data[0].marksTextColor}
                barBgColor={AnalyticsCard2Data[0].barBgColor}
                categoriesData={speakingDataAcademic.categoriesData}
                keepOpen={true}
              />
            )}

            {mockTestType === "Writing" && (
              <AnalyticsCard2
                key={AnalyticsCard2Data[2].id}
                imageSrc={AnalyticsCard2Data[2].imageSrc}
                titleText={AnalyticsCard2Data[2].titleText}
                titleTextColor={AnalyticsCard2Data[2].titleTextColor}
                marksTextColor={AnalyticsCard2Data[2].marksTextColor}
                barBgColor={AnalyticsCard2Data[2].barBgColor}
                categoriesData={writingDataAcademic.categoriesData}
              />
            )}

            {mockTestType === "Reading" && (
              <AnalyticsCard2
                key={AnalyticsCard2Data[3].id}
                imageSrc={AnalyticsCard2Data[3].imageSrc}
                titleText={AnalyticsCard2Data[3].titleText}
                titleTextColor={AnalyticsCard2Data[3].titleTextColor}
                marksTextColor={AnalyticsCard2Data[3].marksTextColor}
                barBgColor={AnalyticsCard2Data[3].barBgColor}
                categoriesData={readingData.categoriesData}
              />
            )}

            {mockTestType === "Listening" && (
              <AnalyticsCard2
                key={AnalyticsCard2Data[1].id}
                imageSrc={AnalyticsCard2Data[1].imageSrc}
                titleText={AnalyticsCard2Data[1].titleText}
                titleTextColor={AnalyticsCard2Data[1].titleTextColor}
                marksTextColor={AnalyticsCard2Data[1].marksTextColor}
                barBgColor={AnalyticsCard2Data[1].barBgColor}
                categoriesData={listeningDataAcademic.categoriesData}
              />
            )}
          </FlexDiv>
        </>
      )}
    </FlexDiv>
  ) : (
    <></>
  );
};

export default AnalyticsForMtScoreSectional;
