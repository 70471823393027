import React, { useState, useEffect } from "react";
import {
  MegaMenuDivHeader,
  MegaMenuDivHeaderLine,
  MegaMenuImg,
  MegaMenuSimpleText,
  MegaMenuSmallImg,
  MegaMenuTextImg,
} from "./style";
import { Btn, FlexDiv } from "../../assets/styles/style";
import MegaMenuOne from "../../assets/images/MegaMenu1.svg";
import MegaMenuTwo from "../../assets/images/MegaMenu2.svg";
import MegaMenuThree from "../../assets/images/MegaMenu3.svg";
import MegaMenuFour from "../../assets/images/MegaMenu4.svg";
import SpeakSImg from "../../assets/images/SpeakingSmallImg.svg";
import {
  SpeakingTests,
  WritingTests,
  ReadingTests,
  ListeningTests,
} from "./data";
import ReadTextImg from "../../assets/images/ReadText.svg";
import ListenTextImg from "../../assets/images/ListenText.svg";
import WriteSImg from "../../assets/images/WritingSmallImg.svg";
import ListenSImg from "../../assets/images/ListenSmallImg.svg";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MegaMenu = ({selectedText}) => {
  const isLaptop = useMediaQuery("(max-width:1440px)");
  const isTab = useMediaQuery("(max-width:1000px)");
  const isSTab = useMediaQuery("(max-width:750px)");
  const isMobile = useMediaQuery("(max-width:500px)");
  const navigate = useNavigate();

  return (
    <>
      <FlexDiv style={{width:isTab?'100%':isLaptop?'92%':'84%',alignItems:'flex-start', flexDirection:isMobile?'column':'', justifyContent:"space-between", padding:isLaptop?isTab?'0px':'0px 4%':'0px 8%', gap:isTab?'2%':''}} id='mega-menu'>
      <FlexDiv
        style={{
          flexDirection: "column",
          gap: "10px",
          alignItems: "flex-start",
          marginBottom:isMobile?'0.75rem':''
        }}
      >
        <FlexDiv style={{ gap: "10px", alignItems: "center", width:'100%'}}>
          <MegaMenuImg alt="" src={MegaMenuOne} />
          {/* <MegaMenuTextImg alt="" src={SpeakTextImg} /> */}
          <FlexDiv style={{flexDirection:'column', width:'100%', alignItems:'flex-start'}}>
            <MegaMenuDivHeader style={{color:'#66E0F7'}}>Speaking</MegaMenuDivHeader>
            <MegaMenuDivHeaderLine style={{background:'#66E0F7'}}/>
          </FlexDiv>
        </FlexDiv>
        {SpeakingTests[selectedText?0:1].map((data) => (
          <>
          <FlexDiv style={{ gap: "8px", marginLeft: "48px" }}>
            <MegaMenuSmallImg src={SpeakSImg} alt="" />
            <Btn
              onClick={() => {
                navigate(data.link);
              }}
            >
              <MegaMenuSimpleText >
                {data.title}
              </MegaMenuSimpleText>
            </Btn>
          </FlexDiv></>
        ))}
        {isTab && !isMobile && (
          <>
            <FlexDiv style={{ gap: "10px", alignItems: "center" }}>
              <MegaMenuImg alt="" src={MegaMenuFour} />
              <MegaMenuTextImg alt="" src={ListenTextImg} />
            </FlexDiv>
            {ListeningTests[selectedText?0:1].map((data) => (
              <FlexDiv style={{ gap: "8px", marginLeft: "48px" }}>
                {data.ai && <MegaMenuSmallImg src={ListenSImg} alt="" />}
                <Btn
                onClick={() => {
                  navigate(data.link);
                }}
              >
                <MegaMenuSimpleText style={{ marginLeft:data.ai?'':'1.5rem' }}>
                  {data.title}
                </MegaMenuSimpleText>
                </Btn>
              </FlexDiv>
            ))}
          </>
        )}
      </FlexDiv>
      <FlexDiv
        style={{
          flexDirection: "column",
          gap: "10px",
          alignItems: "flex-start",
          marginBottom:isMobile?'0.75rem':''
        }}
      >
        <FlexDiv style={{ gap: "10px", alignItems: "center", width:'100%' }}>
          <MegaMenuImg alt="" src={MegaMenuTwo} />
          <FlexDiv style={{flexDirection:'column', width:'100%', alignItems:'flex-start'}}>
            <MegaMenuDivHeader style={{color:'#FF5D5D'}}>Writing</MegaMenuDivHeader>
            <MegaMenuDivHeaderLine style={{background:'#FF5D5D'}}/>
          </FlexDiv>
        </FlexDiv>
        {WritingTests[selectedText?0:1].map((data) => (
          <>
          <FlexDiv style={{ gap: "8px", marginLeft: "48px", alignItems:'flex-start'}}>
            <MegaMenuSmallImg src={WriteSImg} alt="" style={{marginTop:isTab?'':'0.1rem'}}/>
            <Btn
              onClick={() => {
                navigate(data.link);
              }}
            >
              <MegaMenuSimpleText >
                {data.title}
              </MegaMenuSimpleText>
            </Btn>
          </FlexDiv></>
        ))}
        {isSTab && !isMobile && (
          <>
            <FlexDiv
              style={{ gap: "10px", alignItems: "center", marginTop: "88px" }}
            >
              <MegaMenuImg alt="" src={MegaMenuThree} />
              <MegaMenuTextImg alt="" src={ReadTextImg} />
            </FlexDiv>
            {ReadingTests.map((data) => (
              <FlexDiv style={{ gap: "8px", marginLeft: "48px" }}>
                <Btn
                  onClick={() => {
                    navigate(data.link);
                  }}
                >
                  <MegaMenuSimpleText style={{ width: "180px" }}>
                    {data.title}
                  </MegaMenuSimpleText>
                </Btn>
              </FlexDiv>
            ))}
          </>
        )}
      </FlexDiv>
      {(!isSTab || isMobile) && (
        <FlexDiv
          style={{
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            marginBottom:isMobile?'0.75rem':''
          }}
        >
          <FlexDiv style={{ gap: "10px", alignItems: "center", width:'100%' }}>
            <MegaMenuImg alt="" src={MegaMenuThree} />
            <FlexDiv style={{flexDirection:'column', width:'100%', alignItems:'flex-start'}}>
            <MegaMenuDivHeader style={{color:'#AD826E'}}>Reading</MegaMenuDivHeader>
            <MegaMenuDivHeaderLine style={{background:'#AD826E'}}/>
            </FlexDiv>
          </FlexDiv>
          {ReadingTests.map((data) => (
            <FlexDiv style={{ gap: "8px", marginLeft: "48px" }}>
              <Btn
                onClick={() => {
                  navigate(data.link);
                }}
              >
                <MegaMenuSimpleText style={{ maxWidth: "280px" }}>
                  {data.title}
                </MegaMenuSimpleText>
              </Btn>
            </FlexDiv>
          ))}
        </FlexDiv>
      )}
      {(!isTab || isMobile) && (
        <FlexDiv
          style={{
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            marginBottom:isMobile?'0.75rem':''
          }}
        >
          <FlexDiv style={{ gap: "10px", alignItems: "center", width:'100%'}}>
            <MegaMenuImg alt="" src={MegaMenuFour} />
            <FlexDiv style={{flexDirection:'column', width:'100%', alignItems:'flex-start'}}>
              <MegaMenuDivHeader style={{color:'#868EAF'}}>Listening</MegaMenuDivHeader>
              <MegaMenuDivHeaderLine style={{background:'#868EAF'}}/>
            </FlexDiv>            
          </FlexDiv>
          {ListeningTests[selectedText?0:1].map((data) => (
            <FlexDiv style={{ gap: "8px", marginLeft: "48px" }}>
              {data.ai && <MegaMenuSmallImg src={ListenSImg} alt="" />}
              <Btn
                onClick={() => {
                  navigate(data.link);
                }}
              >
                <MegaMenuSimpleText style={{ maxWidth: "280px", marginLeft:data.ai?'':'1.5rem' }}>
                  {data.title}
                </MegaMenuSimpleText>
              </Btn>
            </FlexDiv>
          ))}
        </FlexDiv>
      )}
      </FlexDiv>
    </>
  );
};

export default MegaMenu;
