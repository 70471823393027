import React from "react";
import {
  TestCard2,
  TestCardHeader2,
  TestCardContent2,
  TestCardContentText2,
  TestCardContentDigit2,
} from "./style";
import { FlexDiv } from "../../assets/styles/style";

const AnalyticsTestCard2 = ({
  headerName,
  imgSrc,
  color,
  QuestinsTotalTime,
  QuestionNames,
  QuestionTimes,
}) => {
  return (
    <TestCard2>
      <TestCardHeader2 style={{ backgroundColor: color }}>
        <FlexDiv style={{ gap: "0.38rem" }}>
          <img src={imgSrc} alt={headerName} />
          {headerName}
        </FlexDiv>
        {QuestinsTotalTime}
      </TestCardHeader2>
      <TestCardContent2>
        {QuestionNames.map((name, index) => (
          <FlexDiv
            key={index}
            style={{
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <TestCardContentText2>{name}</TestCardContentText2>
            <TestCardContentDigit2>
              {QuestionTimes[index]}
            </TestCardContentDigit2>
          </FlexDiv>
        ))}
      </TestCardContent2>
    </TestCard2>
  );
};

export default AnalyticsTestCard2;
