import React, {useEffect, useState} from "react";
import TestProgress from "../components/Home/TestProgress";
import { FlexDiv } from "../assets/styles/style";
import AverageScore from "../components/Home/AverageScore";
import AiStudyPlan from "../components/Home/AiStudyPlan";
import Navbar from "../components/Navbar/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "../components/Home/Footer";
import BrowserNotSupported from "./BrowserNotSupported";

const isNotChrome = () => {
  const userAgent = navigator.userAgent;
  return !/Chrome/.test(userAgent) || /OPR|Edge/.test(userAgent); // Excludes Chrome but accounts for Opera & Edge (which may include Chrome in UA)
};

const Home = () => {
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isNotChrome());
  }, []);

  return (
    <>
      <BrowserNotSupported open={open} close={setOpen}/>
      <Navbar />
      <FlexDiv
        style={{ padding: isLaptopTwo ? "1.5rem 3% 0rem" : "6.5rem 3% 0rem" }}
      >
        <AiStudyPlan />
      </FlexDiv>
      <div style={{ padding: "0rem 3% 0rem" }}>
        <AverageScore />
      </div>
      <FlexDiv style={{ padding: "1.5rem 3%" }}>
        <TestProgress />
      </FlexDiv>

      <FlexDiv
        style={{
          padding: isLaptopTwo ? "0rem 3% 0rem" : "0rem 3% 0rem",
          background: "white",
        }}
      >
        <Footer />
      </FlexDiv>
    </>
  );
};

export default Home;
