import React from 'react'
import { FlexDiv } from '../../assets/styles/style'
import { AverageScoreText } from './style'
import AvgScoreCard from './AvgScoreCard'
import { AvgScoreCardData } from './data'
import { useMediaQuery } from '@mui/material'

const AverageScore = () => {
    const isTab = useMediaQuery('(max-width:920px)')
    const isSTab = useMediaQuery('(max-width:700px)')

    return (
    !isSTab?
    <>
    <FlexDiv style={{paddingTop:'0.25rem'}}>
    <AverageScoreText>Your Average Score</AverageScoreText>
    </FlexDiv>
    <FlexDiv
        style={{
            flexDirection:'column',
            paddingTop:'12px',
            gap:'12px'
        }}
    >
        <FlexDiv
            style={{
                width:'100%',
                maxWidth: "1680px",
            }}
        >
            <AvgScoreCard
                key={AvgScoreCardData[0].id}
                borderRadius={AvgScoreCardData[0].borderRadius}
                backgroundColor={AvgScoreCardData[0].backgroundColor}
                text={AvgScoreCardData[0].text}
                score={AvgScoreCardData[0].score}
                marginRight={'1%'}
            />
            <AvgScoreCard
                key={AvgScoreCardData[1].id}
                borderRadius={AvgScoreCardData[1].borderRadius}
                backgroundColor={AvgScoreCardData[1].backgroundColor}
                text={AvgScoreCardData[1].text}
                score={AvgScoreCardData[1].score}
            />
            <AvgScoreCard
                key={AvgScoreCardData[2].id}
                borderRadius={AvgScoreCardData[2].borderRadius}
                backgroundColor={AvgScoreCardData[2].backgroundColor}
                text={AvgScoreCardData[2].text}
                score={AvgScoreCardData[2].score}
            />
            <AvgScoreCard
            key={AvgScoreCardData[3].id}
            borderRadius={AvgScoreCardData[3].borderRadius}
            backgroundColor={AvgScoreCardData[3].backgroundColor}
            text={AvgScoreCardData[3].text}
            score={AvgScoreCardData[3].score}
            />
            <AvgScoreCard
            key={AvgScoreCardData[4].id}
            borderRadius={AvgScoreCardData[4].borderRadius}
            backgroundColor={AvgScoreCardData[4].backgroundColor}
            text={AvgScoreCardData[4].text}
            score={AvgScoreCardData[4].score}
            />
        </FlexDiv>
    </FlexDiv>
    </>
    :
    <FlexDiv
            style={{
                flexDirection:'column',
                gap:'12px'
            }}
    >
        <AverageScoreText>Your Average Score</AverageScoreText>
        <FlexDiv
            style={{width:'100%'}}
        >
        <AvgScoreCard
            key={AvgScoreCardData[0].id}
            borderRadius={AvgScoreCardData[0].borderRadius}
            backgroundColor={AvgScoreCardData[0].backgroundColor}
            text={AvgScoreCardData[0].text}
            score={AvgScoreCardData[0].score}
        />
        </FlexDiv>
        <FlexDiv
        style={{width:'49%', gap:'4%'}}        
        >
            <AvgScoreCard
                key={AvgScoreCardData[1].id}
                borderRadius={AvgScoreCardData[1].borderRadius}
                backgroundColor={AvgScoreCardData[1].backgroundColor}
                text={AvgScoreCardData[1].text}
                score={AvgScoreCardData[1].score}
            />
            <AvgScoreCard
                key={AvgScoreCardData[2].id}
                borderRadius={AvgScoreCardData[2].borderRadius}
                backgroundColor={AvgScoreCardData[2].backgroundColor}
                text={AvgScoreCardData[2].text}
                score={AvgScoreCardData[2].score}
            />
        </FlexDiv>
        <FlexDiv
        style={{width:'49%', gap:'4%'}}        
        >
            <AvgScoreCard
                key={AvgScoreCardData[3].id}
                borderRadius={AvgScoreCardData[3].borderRadius}
                backgroundColor={AvgScoreCardData[3].backgroundColor}
                text={AvgScoreCardData[3].text}
                score={AvgScoreCardData[3].score}
            />
            <AvgScoreCard
                key={AvgScoreCardData[4].id}
                borderRadius={AvgScoreCardData[4].borderRadius}
                backgroundColor={AvgScoreCardData[4].backgroundColor}
                text={AvgScoreCardData[4].text}
                score={AvgScoreCardData[4].score}
            />
        </FlexDiv>
    </FlexDiv>
    
  )
}

export default AverageScore