import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

function useTimer(initialDuration) {
  const [duration, setDuration] = useState(moment.duration(initialDuration));
  const [isActive, setIsActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(duration.asMilliseconds());
  const intervalRef = useRef(null);

  useEffect(() => {
    setTimeLeft(duration.asMilliseconds());
  }, [duration]);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1000) {
            clearInterval(intervalRef.current);
            setIsActive(false);
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const startTimer = (newDuration) => {
    if (newDuration) {
      const newDurationObj = moment.duration(newDuration);
      setDuration(newDurationObj);
      setTimeLeft(newDurationObj.asMilliseconds());
    }
    setIsActive(true);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const resetTimer = (newDuration) => {
    setDuration(moment.duration(newDuration));
    setIsActive(false);
  };

  return { timeLeft, isActive, startTimer, stopTimer, resetTimer };
}

export default useTimer;
