import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Feedback from '../components/MT_Score/Feedback'

const MtScoreFeedback = () => {
  return (
    <>
    <Navbar />
    <Feedback />
    </>
  )
}

export default MtScoreFeedback