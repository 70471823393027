import { Box, CircularProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CLOCK, QUESTIONS } from "../../constants/Mocktests";
import SwiftUniLogo from "../../assets/images/swiftuniLogoo.svg";
import Writing from "./Writing/WritingFull";
import Speaking from "./Speaking/SpeakingFull";
import Reading from "./Reading/ReadingFull";
import Listening from "./Listening/ListeningFull";
import CompatibilityModal from "./CompatibilityModal";
import {
  EndTest,
  GetAllMocktestQuestion,
  GetPendingMocktestQuestion,
  Timeout,
} from "../../Client/request";
import { useLocation, useNavigate } from "react-router-dom";
import { PurpleBtn } from "../Common/Style";
import Welcome from "./Welcome";
import { Snackbar } from "@mui/joy";
import moment from "moment";
import useTimer from "../../hooks/useTimer";
import RemainingTime from "./RemainingTime";
import useStopwatch from "../../hooks/useStopwatch";

export default function MockTest() {
  const location = useLocation();
  const { Exam } = location.state || {};
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const isMobile = useMediaQuery("(max-width:450px)");
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(true);
  const [InitStep, setInitStep] = useState(1);
  const [MockTestAttemptID, setMockTestAttemptID] = useState();
  const [snackbarModel, setsnackbarModel] = useState(false);
  const [Endloader, setEndloader] = useState(false);
  const [timeoutModel, setTimeoutModel] = useState(false);
  const [showRemainingTime, setShowRemainingTime] = useState(null);
  const [commulative, setCommulative] = useState(0);
  const [InitTimer, setInitTimer] = useState(0);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [RemainingListeningTime, setRemainingListeningTime] = useState(0);
  const [summarizeSpokenTextCount, setSummarizeSpokenTextCount] = useState(0);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "24px",
    p: isMobile ? 2 : 4,
    height: "80vh", // maximum height
    overflowY: "auto",
  };

  useEffect(() => {
    if (isTestStarted) {
      startStopwatch();
      setIsTestStarted(false);
    }
  }, [isTestStarted]);

  const individualTimes = JSON.parse(Exam.IndividualTimes);

  const speakingTime = individualTimes.speaking_time;
  const writingTime = individualTimes.writing_time;
  const readingTime = individualTimes.reading_time;
  const listeningTime = individualTimes.listening_time;

  // const testTime = {
  //   speaking: speakingTime,
  //   writing: writingTime,
  //   reading: readingTime,
  //   listening: listeningTime,
  // };

  const totalSpeakingWritingTime = speakingTime + writingTime;

  const {
    elapsedTime,
    isActive,
    startStopwatch,
    stopStopwatch,
    resetStopwatch,
    addTime,
  } = useStopwatch(moment.duration(speakingTime, "minutes"));

  // useEffect(() => {
  //   if (isTestStarted) {
  //     startStopwatch();
  //   }
  // }, [isTestStarted]);

  useEffect(() => {
    if (isTestStarted && !isActive) {
      startStopwatch();
    }
  }, [isTestStarted, isActive]);

  //Remaining Listening Time based on the number of "Summarize Spoken Text" questions
  useEffect(() => {
    if (questions.length > 0) {
      let sumSpokenTextCount = questions.filter(
        (q) =>
          q.Category === "Listening" &&
          q.SubCategory === "Summarize Spoken Text"
      ).length;

      setSummarizeSpokenTextCount(sumSpokenTextCount);

      const adjustedListeningTime = listeningTime - sumSpokenTextCount * 10;
      setRemainingListeningTime(adjustedListeningTime);
    }
  }, [questions, listeningTime]);

  //timeout useEffect
  useEffect(() => {
    const timeCheck =
      questions[step - 1]?.Category === "Reading"
        ? readingTime
        : questions[step - 1]?.Category === "Listening"
        ? RemainingListeningTime
        : totalSpeakingWritingTime;

    // console.log("timecheck", moment.duration(timeCheck, "minutes"));

    if (
      questions.length > 0 &&
      elapsedTime >= moment.duration(timeCheck, "minutes").asMilliseconds()
    ) {
      const initialCategory = questions[step]?.Category;
      if (!initialCategory) return;

      if (initialCategory === "Listening") {
        // Call the timeout API
        setEndloader(true);
        const payload = {
          mock_test_attempt_id: MockTestAttemptID,
          is_ptecore: false,
          response_obj: JSON.stringify(
            questions.slice(step - 1, questions.length)
          ),
        };
        Timeout(payload);
        const Endpayload = {
          mock_test_attempt_id: MockTestAttemptID
            ? MockTestAttemptID
            : Exam.ResumeTestId,
          end_time: new Date().toLocaleDateString(),
        };
        EndTest(Endpayload).then((res) => {
          if (res.data) {
            // navigate("/MockTest"); //mt-score
            console.log(
              `Navigating to /mt-score-viewscore/${
                MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
              }`
            );
            navigate(
              `/mt-score-viewscore/${
                MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
              }`
            );
            setEndloader(false);
          }
        });
      } else {
        // Slicing logic
        let endIndex = step;
        // Find the index where the category changes
        for (let i = step; i < questions.length; i++) {
          if (questions[i].Category !== initialCategory) {
            if (
              initialCategory === "Speaking" &&
              questions[i].Category === "Writing"
            ) {
              continue;
            } else {
              endIndex = i;
              break;
            }
          }
        }

        // If no category change is found, slice till the end of the array
        if (endIndex === step) {
          endIndex = questions.length;
        }

        // Slice the array
        const slicedArray = questions.slice(step - 1, endIndex);

        const payload = {
          mock_test_attempt_id: MockTestAttemptID,
          is_ptecore: false,
          response_obj: JSON.stringify(slicedArray),
        };
        Timeout(payload);
        setTimeoutModel(true);

        const FinalIndex =
          endIndex + 1 > questions.length ? endIndex - 1 : endIndex + 1;

        setStep(FinalIndex);

        switch (questions[FinalIndex].Category) {
          case "Writing":
            setInitStep(1);
            break;
          case "Reading":
            resetStopwatch(moment.duration(readingTime, "minutes"));
            setInitStep(2);
            break;
          case "Listening":
            resetStopwatch(moment.duration(listeningTime, "minutes"));
            setInitStep(3);
            break;
        }
      }
    }
  }, [elapsedTime]);

  // useEffect(() => {
  //   // if (!questions.length || !questions[step - 1]) return; // Safety check

  //   const timeCheck = getTimeCheck(questions[step - 1].Category);
  //   if (elapsedTime >= moment.duration(timeCheck, "minutes").asMilliseconds()) {
  //     const initialCategory = questions[step]?.Category;
  //     if (!initialCategory) return;

  //     if (initialCategory === "Listening") {
  //       handleListeningTimeout();
  //     } else {
  //       handleOtherCategoryTimeout(initialCategory);
  //     }
  //   }
  // }, [elapsedTime, questions, step]);

  // // Helper Function to get time based on category
  // const getTimeCheck = (category) => {
  //   return category === "Reading"
  //     ? readingTime
  //     : category === "Listening"
  //     ? listeningTime
  //     : totalSpeakingWritingTime;
  // };

  // // Helper Function to handle timeout logic for Listening category
  // const handleListeningTimeout = () => {
  //   setEndloader(true);

  //   const payload = {
  //     mock_test_attempt_id: MockTestAttemptID,
  //     is_ptecore: false,
  //     response_obj: JSON.stringify(questions.slice(step - 1)),
  //   };

  //   Timeout(payload).then(() => {
  //     const Endpayload = {
  //       mock_test_attempt_id: MockTestAttemptID || Exam.ResumeTestId,
  //       end_time: new Date().toLocaleDateString(),
  //     };

  //     EndTest(Endpayload).then((res) => {
  //       if (res.data) {
  //         navigate("/MockTest");
  //         setEndloader(false);
  //       }
  //     });
  //   });
  // };

  // // Helper Function to handle timeout logic for non-Listening categories
  // const handleOtherCategoryTimeout = (initialCategory) => {
  //   const endIndex = findEndIndexForCategory(initialCategory);
  //   const slicedArray = questions.slice(step - 1, endIndex);

  //   const payload = {
  //     mock_test_attempt_id: MockTestAttemptID,
  //     is_ptecore: false,
  //     response_obj: JSON.stringify(slicedArray),
  //   };

  //   Timeout(payload).then(() => {
  //     setTimeoutModel(true);
  //     const FinalIndex = endIndex + 1 > questions.length ? endIndex - 1 : endIndex + 1;
  //     setStep(FinalIndex);
  //     handleCategoryTransition(questions[FinalIndex]?.Category);
  //   });
  // };

  // // Helper Function to find the index where the category changes
  // const findEndIndexForCategory = (initialCategory) => {
  //   let endIndex = step;

  //   for (let i = step; i < questions.length; i++) {
  //     if (questions[i].Category !== initialCategory) {
  //       if (initialCategory === "Speaking" && questions[i].Category === "Writing") {
  //         continue; // Skip writing if it follows speaking
  //       } else {
  //         return i;
  //       }
  //     }
  //   }
  //   return questions.length;
  // };

  // // Helper Function to handle category transitions and reset stopwatch
  // const handleCategoryTransition = (nextCategory) => {
  //   switch (nextCategory) {
  //     case "Writing":
  //       setInitStep(1);
  //       break;
  //     case "Reading":
  //       resetStopwatch(moment.duration(readingTime, "minutes"));
  //       setInitStep(2);
  //       break;
  //     case "Listening":
  //       resetStopwatch(moment.duration(listeningTime, "minutes"));
  //       setInitStep(3);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  useEffect(() => {
    if (showRemainingTime) {
      if (questions.length > 0 && step > 1) {
        if (InitStep === 2) {
          console.log("I AM HEREEEE FOR RESETTTTT!!!!");
          resetStopwatch(moment.duration(readingTime, "minutes"));
        } else if (InitStep === 3) {
          resetStopwatch(moment.duration(listeningTime, "minutes"));
        }
      }
    }
  }, [showRemainingTime]);

  // useEffect(() => {
  //   if (InitTimer > 0) {
  //     addTime(InitTimer);
  //   }
  // }, [InitTimer]);

  useEffect(() => {
    if (Exam.ResumeTestId) {
      GetPendingMocktestQuestion({ id: Exam.ResumeTestId }).then((res) => {
        if (res.responseCode === 200) {
          setQuestions(res.response || []);

          //newIndex is the the first false entry
          const NewIndex = res.response.findIndex(
            (element) => element.IsAttempted === false
          );

          setStep(NewIndex + 1);

          // console.log("newindex",NewIndex,res.response[NewIndex - 1].Category,res.response[NewIndex-2].Category,res.response[NewIndex-2].QuestionId);
          
          if (
            NewIndex > 0 &&
            res.response[NewIndex - 1].Category ===
              res.response[NewIndex].Category
          ) {
            let previousElapsedTime = 0;
            previousElapsedTime = JSON.parse(
              res.response[NewIndex-1].AllTimes
            )?.commulativeTime;
            console.log("previos time",previousElapsedTime);
            
            if (previousElapsedTime > 0) {
              console.log("previousElapsedTime : ", previousElapsedTime);
              setInitTimer(previousElapsedTime);
              resetStopwatch();
              addTime(previousElapsedTime);
            }
          }

          switch (res.response[NewIndex].Category) {
            case "Writing":
              setInitStep(1);
              break;
            case "Reading":
              setInitStep(2);
              break;
            case "Listening":
              setInitStep(3);
              break;
          }
        }
      });
    } else {
      GetAllMocktestQuestion({ id: Exam.MockTestId }).then((res) => {
        if (res.responseCode === 200) {
          setMockTestAttemptID(res.response.MockTestAttemptId);
          setQuestions(res.response.obj || []);
        } else if (res.responseCode === 400) {
          setsnackbarModel(true);
          setTimeout(() => navigate("/MockTest"), 1000);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (questions && step < questions.length) {

  //     if (questions[step - 1].Category === "Writing") {
  //       if (questions[step - 1].SubCategory === "Write Essay") {
  //         setShowRemainingTime(20);
  //       } else if (questions[step - 1].SubCategory === "Summarize Written Text") {
  //         setShowRemainingTime(10);
  //       } else if (questions[step - 1].SubCategory === "Write Email") {
  //         setShowRemainingTime(9);
  //       }
  //     }

  //     else if (questions[step - 1].Category === "Listening") {
  //       if (questions[step - 1].SubCategory === "Summarize Spoken Text") {
  //         setShowRemainingTime(10);
  //       } else if ( questions[step - 1]?.SubCategory !== "Summarize Spoken Text") {
  //         setShowRemainingTime(null);
  //         // startStopwatch();
  //       }
  //     }

  //     // else if (questions[step - 1].Category === "Reading" && !isReadingInitialized) {
  //     //   setShowRemainingTime(null);
  //     //   startStopwatch();
  //     //   setIsReadingInitialized(true);
  //     // }

  //     else {
  //       setShowRemainingTime(null);
  //     }
  //   }
  // }, [step, questions]);

  useEffect(() => {
    if (questions && step < questions.length) {
      const currentQuestion = questions[step - 1];
      const currentCategory = currentQuestion.Category;
      const currentSubCategory = currentQuestion.SubCategory;

      // Handle Writing Section Remaining Timer
      if (currentCategory === "Writing") {
        if (currentSubCategory === "Write Essay") {
          setShowRemainingTime(20); // Set Remaining Timer for 20 minutes for Write Essay
        } else if (currentSubCategory === "Summarize Written Text") {
          setShowRemainingTime(10); // Set Remaining Timer for 10 minutes for Summarize Written Text
        } else if (currentSubCategory === "Write Email") {
          setShowRemainingTime(9); // Set Remaining Timer for 9 minutes for Write Email
        }
      }

      // Handle Listening Section Remaining Timer and Incremental Stop Watch
      else if (currentCategory === "Listening") {
        if (currentSubCategory === "Summarize Spoken Text") {
          setShowRemainingTime(10); // Set RemainingTime for 10 minutes for Summarize Spoken Text
        } else if (currentSubCategory !== "Summarize Spoken Text") {
          if(showRemainingTime){
            resetStopwatch(moment.duration(listeningTime, "minutes"));
            console.log("currentCategory",currentCategory,questions[step-2].Category);
            
            currentCategory === questions[step-2].Category && startStopwatch();
          } 
          setShowRemainingTime(null); // Don't show Remaining Timer for other Listening questions
          // startStopwatch(); // Start Incremental stop watch for other Listening questions
        }
      }

      // Handle Reading Section
      else if (currentCategory === "Reading") {
        if(showRemainingTime){
          resetStopwatch(moment.duration(readingTime, "minutes"));
          console.log("currentCategory",currentCategory,questions[step-2].Category);
          currentCategory === questions[step-2].Category && startStopwatch();
        }
        setShowRemainingTime(null);
        // startStopwatch();
      }

      // Reset Stopwatch for Other Categories
      else {
        console.log("its not Writing, Reading, or Listening Category");
        setShowRemainingTime(null);
      }
    }
  }, [step, questions]);

  useEffect(() => {
    console.log("Elapsed Time in Paren Component MockTest():", elapsedTime);
  }, [elapsedTime]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={timeoutModel}
        variant={"filled"}
        color={"danger"}
        onClose={() => setTimeoutModel(false)}
      >
        Time ended for this section
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={snackbarModel}
        variant={"filled"}
        color={"danger"}
        onClose={() => setsnackbarModel(false)}
      >
        You have already started this test !!
      </Snackbar>
      <Modal
        open={open}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(3px)",
        }}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <CompatibilityModal setOpen={setOpen} test={Exam} />
        </Box>
      </Modal>
      {/* header */}
      {questions.length > 0 && (
        <div
          style={{
            padding: isLaptopTwo ? "15px 10px" : "5px 100px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={SwiftUniLogo} alt="logo" />
            {!isMobile && <h1>Mock Test</h1>}
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              {CLOCK}
              {showRemainingTime ? (
                <RemainingTime
                  showRemainingTime={showRemainingTime}
                  setShowRemainingTime={setShowRemainingTime}
                  stopStopwatch={stopStopwatch}
                  addTime={addTime}
                  Category={questions[step - 1].Category}
                  time={
                    questions[step - 1].Category === "Reading"
                      ? readingTime
                      : questions[step - 1].Category === "Listening"
                      ? listeningTime
                      : speakingTime
                  }
                  step={step}
                  setStep={setStep}
                  InitStep={InitStep}
                  questions={questions}
                />
              ) : (
                <span>
                  {moment.duration(elapsedTime).hours() > 0
                    ? moment.utc(elapsedTime).format("hh:mm:ss")
                    : moment.utc(elapsedTime).format("mm:ss")}
                  /
                  {questions.length > 0 &&
                    moment
                      .duration(
                        questions[step - 1].Category === "Reading"
                          ? readingTime
                          : questions[step - 1].Category === "Listening"
                          ? RemainingListeningTime
                          : speakingTime,
                        "minutes"
                      )
                      .minutes() + ":00"}
                </span>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {QUESTIONS}
              <span>
                {step} of {questions.length}
              </span>
            </div>
          </div>
        </div>
      )}
      {Endloader ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100vw",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : questions.length > 0 ? (
        <>
          {questions[step - 1].Category === "Speaking" ? (
            InitStep === 1 ? (
              <Welcome
                Exam={Exam.MockTestName}
                InitStep={InitStep}
                setInitStep={setInitStep}
                part={1}
                name={"Speaking & Writing"}
                totalTime={totalSpeakingWritingTime}
                speakingTime={speakingTime} // Passing speaking time separately
                writingTime={writingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
              />
            ) : (
              <Speaking
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
              />
            )
          ) : questions[step - 1].Category === "Writing" ? (
            InitStep === 444 ? ( //invalid value
              <Welcome
                Exam={Exam.MockTestName}
                InitStep={InitStep}
                setInitStep={setInitStep}
                part={2}
                name={"Writing"}
                totalTime={writingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
              />
            ) : (
              <Writing
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                isResuming={!!Exam.ResumeTestId}
              />
            )
          ) : questions[step - 1].Category === "Reading" ? (
            InitStep === 2 ? (
              <Welcome
                Exam={Exam.MockTestName}
                InitStep={InitStep}
                setInitStep={setInitStep}
                part={2}
                name={"Reading"}
                setCommulative={setCommulative}
                totalTime={readingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
              />
            ) : (
              <Reading
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
              />
            )
          ) : questions[step - 1].Category === "Listening" ? (
            InitStep === 3 ? (
              <Welcome
                Exam={Exam.MockTestName}
                InitStep={InitStep}
                setInitStep={setInitStep}
                part={3}
                name={"Listening"}
                totalTime={listeningTime}
                RemainingListeningTime={RemainingListeningTime}
                summarizeSpokenTextTime={summarizeSpokenTextCount * 10}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
              />
            ) : (
              <Listening
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                setShowRemainingTime={setShowRemainingTime}
              />
            )
          ) : null}
        </>
      ) : null}
    </div>
  );
}
