import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Page from '../components/MT_Score/Page'

const MT_Score = () => {
  return (
    <>
        <Navbar />
        <Page />
    </>
  )
}

export default MT_Score