import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToggleContainer, LeftButton, RightButton, Slider } from "./style";

const PteToggleBtn = ({active, setActive}) => {
  const navigate = useNavigate();

  const handleClick = (value) => {
    if(active === value){
      return;
    }
    localStorage.setItem("pte-type", value === 1 ? "pte core" : "pte academic");
    setActive(value);
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  return (
    <ToggleContainer>
      <Slider isActive={active} />
      <LeftButton isActive={active === 0} onClick={() => handleClick(0)}>
        PTE
      </LeftButton>
      <RightButton isActive={active === 1} onClick={() => handleClick(1)}>
        PTE-Core
      </RightButton>
    </ToggleContainer>
  );
};

export default PteToggleBtn;
