import { useState, useEffect, useRef } from 'react';

function useStopwatch(initialDuration) {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [duration, setDuration] = useState(initialDuration || null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setElapsedTime(prev => {
          const newElapsedTime = prev + 1000;
          if (duration && newElapsedTime >= duration) {
            clearInterval(intervalRef.current);
            setIsActive(false);
            return duration; // Ensure elapsedTime doesn't exceed duration
          }
          return newElapsedTime;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isActive, duration]);

  console.log("Elapsed Time :", elapsedTime / 1000);

  const startStopwatch = () => {
    setIsActive(true);
  };

  const stopStopwatch = () => {
    setIsActive(false);
  };

  const resetStopwatch = (newDuration = null) => {
    setElapsedTime(0);
    setIsActive(false);
    if (newDuration !== null) {
      setDuration(newDuration);
    }
  };

  const addTime = (seconds = 0) => {
    setElapsedTime(prev => {
      const newElapsedTime = prev + seconds * 1000;
      if (duration && newElapsedTime >= duration) {
        clearInterval(intervalRef.current);
        setIsActive(false);
        return duration; // Ensure elapsedTime doesn't exceed duration
      }
      return newElapsedTime;
    });
  }
  return { elapsedTime, isActive, startStopwatch, stopStopwatch, resetStopwatch ,addTime};
}

export default useStopwatch;
