import React, { useEffect, useRef, useState } from "react";
import { CLOCK, QUESTIONS } from "../../../constants/Mocktests";
import SwiftUniLogo from "../../../assets/images/swiftuniLogoo.svg";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { PurpleTextArea } from "../Style";
import RecordingComponent from "./RecordingComponent";
import Filler from "../../../assets/audios/filler_audio.mp3";
import { PurpleBtn } from "../../Common/Style";
import {
  AnswerShortQuestion,
  DescribeImage,
  EndTest,
  GetMocktestQuestion,
  MakeQuestionAttempted,
  ReadAloud,
  RepeatSentence,
  RetellLecture,
} from "../../../Client/request";
import { useNavigate } from "react-router-dom";
import useStopwatch from "../../../hooks/useStopwatch";
import { Snackbar } from "@mui/joy";

const guideline = {
  "Read Aloud":
    "Look at the text below. In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.",
  "Repeat Sentence":
    "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.",
  "Describe Image":
    "Look at the image below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response.",
  "Re-tell Lecture":
    "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.",
  "Answer Short Question":
    "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
};

export default function SpeakingFull({
  ExamId,
  quest,
  step,
  setStep,
  MockTestAttemptID,
  totalQuestions,
  commulative,
  setCommulative,
  stopRecordingProcess,
}) {
  const [transcript, setTranscript] = useState("");
  const audioRef = useRef(null);
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const isLaptopOne = useMediaQuery("(max-width:1400px)");
  const [Loading, setLoading] = useState(true);
  const [AudioUrl, setAudioUrl] = useState();
  const [ImageLoading, setImageLoading] = useState(true);
  const [quest_data, setQuest_data] = useState();
  const [DisabledButton, setDisabledButton] = useState(true);
  const mediaRecorderRef = useRef(null);
  const [clicked, setclicked] = useState(false);
  const {
    elapsedTime,
    isActive,
    startStopwatch,
    stopStopwatch,
    resetStopwatch,
  } = useStopwatch();
  const [tempQuest, setTempQuest] = useState(quest);
  const [tempQuestData, setTempQuestData] = useState(quest_data);
  const [tempTranscript, setTempTranscript] = useState("");
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [tempTime, setTempTime] = useState(0);
  const [NetworkError, setNetworkError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setQuest_data(null);
  }, []);

  function formatText(text) {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  const handleExit = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the audio
    }
    navigate("/MockTest");
  };

  useEffect(() => {
    if (AudioUrl && AudioUrl.size > 0 && !transcriptLoading) {
      if (step !== totalQuestions) {
        setStep(step + 1);
      }
      setLoading(true);
      setCommulative(commulative + tempTime);
      if (tempQuest.SubCategory === "Read Aloud") {
        let AI_payload = {
          audiofile: AudioUrl,
          script: tempQuestData.QuestionStatement,
          user_response: tempTranscript,
        };

        ReadAloud(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: tempTime,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + tempTime,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Repeat Sentence") {
        let AI_payload = {
          audiofile: AudioUrl,
          correct_text: tempQuestData.QuestionStatement,
          user_text: tempTranscript,
        };
        RepeatSentence(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: tempTime,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + tempTime,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Describe Image") {
        // console.log("ai payload", AudioUrl);
        let AI_payload = {
          audiofile: AudioUrl,
          user_text: tempTranscript,
          major_aspects: JSON.stringify(tempQuestData.MajorAspects.flat()),
          minor_aspects: JSON.stringify(tempQuestData.MinorAspects.flat()),
          // major_aspect: Quest_data.MajorAspects.flat(),
          // minor_aspect: Quest_data.MinorAspects.flat(),
        };
        DescribeImage(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: tempTime,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + tempTime,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Re-tell Lecture") {
        let AI_payload = {
          audiofile: AudioUrl,
          script: tempQuestData.QuestionStatement,
          user_response: tempTranscript,
        };
        RetellLecture(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript,
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: tempTime,
              is_ptecore: false,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + tempTime,
              }),
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Answer Short Question") {
        let AI_payload = {
          question: tempQuestData.QuestionStatement,
          user_answer: tempTranscript,
          answer_list: tempQuestData.OptionNames,
        };
        AnswerShortQuestion(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: tempTime,
              is_ptecore: false,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + tempTime,
              }),
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest"); ///mt-score
                  }
                });
              }
            });
          }
        });
      }
      setQuest_data(null);
    }
  }, [AudioUrl, transcriptLoading]);

  const handleNext = () => {
    if (DisabledButton) {
      return;
    }
    if (audioRef.current) {
      audioRef.current.pause();
    }
    setTempQuest(quest);
    setTempQuestData(quest_data);
    if (transcript !== "") {
      setTempTranscript(transcript);
    }
    //useeffect api here
    setDisabledButton(true);
    console.log(
      "Elapsed Time before setting tempTime in Speaking:",
      elapsedTime
    );
    setTempTime(elapsedTime / 1000);
    stopStopwatch();
    resetStopwatch();
    setclicked(!clicked);
  };

  useEffect(() => {
    if (quest) {
      setLoading(true);
      GetMocktestQuestion(quest.QuestionId).then((res) => {
        if (res.data) {
          setQuest_data(res.data);
          setLoading(false);
          setTranscript("");
          startStopwatch();
        }
      });
    }
  }, [quest]);

  return (
    <div style={{}}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={NetworkError}
        variant={"outlined"}
        color={"danger"}
        onClose={() => {
          setNetworkError(false);
        }}
      >
        Network Error
      </Snackbar>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isLaptopTwo ? "1rem 5% 2rem" : "3rem 5% 2rem",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            // height: "400px",
            width: "100%",
            borderRadius: "24px",
            position: "relative",
            overflow: "clip",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#e2e2ea",
              height: "13px",
              position: "absolute",
            }}
          ></div>
          <div
            style={{
              width: `${(step / totalQuestions) * 100}%`,
              backgroundColor: "#996cfe",
              height: "13px",
              position: "absolute",
            }}
          ></div>
          {Loading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "full",
                minHeight: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                //   width: "100%",
                height: "100%",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "32px",
                  userSelect: "none",
                }}
                onCopy={(e) => e.preventDefault()}
              >
                {guideline[quest.SubCategory]}
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: isLaptopOne ? "column" : "row",
                }}
              >
                {quest.SubCategory === "Describe Image" && (
                  <div
                    style={{
                      minWidth: isLaptopOne ? "100px" : "627px",
                      minHeight: isLaptopOne ? "100px" : "536px",
                      maxWidth: "800px",
                      borderRadius: "8px",
                      backgroundColor: "rgba(153, 108, 254, 0.10)",
                      overflow: "hidden", // Ensures the image doesn't overflow the div
                      display: "flex", // Centers the image
                      alignItems: "center", // Centers the image vertically
                      justifyContent: "center", // Centers the image horizontally
                    }}
                  >
                    {ImageLoading && <CircularProgress />}
                    <img
                      src={quest_data.QuestionImage}
                      alt="img"
                      onLoad={() => {
                        setImageLoading(false);

                        //  console.log("onload ened");
                      }}
                      style={{
                        maxWidth: "80%", // Ensures the image fits within the div's width
                        maxHeight: "90%", // Ensures the image fits within the div's height
                        display: ImageLoading ? "hidden" : "block",
                      }}
                    />
                  </div>
                )}
                <div style={{ width: isLaptopOne ? "100%" : "40%" }}>
                  {/* {console.log("quest data", quest_data, quest.SubCategory)} */}
                  {quest_data && (
                    <RecordingComponent
                      callNext={() => handleNext()}
                      setTempTranscript={setTempTranscript}
                      clicked={clicked}
                      step={step}
                      transcript={transcript}
                      setAudioUrl={setAudioUrl}
                      setTranscript={setTranscript}

                      
                      Beginning={ //Time Before Starting The Audio
                            quest.SubCategory === "Answer Short Question" ? 3
                          : quest.SubCategory === "Describe Image"  ? 0
                          : quest.SubCategory === "Re-tell Lecture" ? 3
                          : quest.SubCategory === "Repeat Sentence" ? 3
                          : quest.SubCategory === "Respond to a situation" ? 3
                          : 0
                      }
                      Waiting={ //Time After The Audio Has Been Player
                            quest.SubCategory === "Answer Short Question" ? 2
                          : quest.SubCategory === "Describe Image"  ? 25
                          : quest.SubCategory === "Re-tell Lecture" ? 10
                          : quest.SubCategory === "Repeat Sentence" ? 2
                          : quest.SubCategory === "Respond to a situation" ? 20
                          : 35
                      }
                      RecordingTime={ //Time of Recording The User Response
                            quest.SubCategory === "Answer Short Question" ? 7
                          : quest.SubCategory === "Re-tell Lecture" ? 40
                          : quest.SubCategory === "Repeat Sentence" ? 15
                          : quest.SubCategory === "Describe Image"  ? 40
                          : quest.SubCategory === "Respond to a situation" ? 15
                          : 40
                      }

                //       Beginning={
                //         //Time Before Starting The Audio
                //         quest.SubCategory === "Answer Short Question"
                //           ? 0
                //           : quest.SubCategory === "Describe Image"
                //           ? 0
                //           : quest.SubCategory === "Re-tell Lecture"
                //           ? 0
                //           : quest.SubCategory === "Repeat Sentence"
                //           ? 0
                //           : quest.SubCategory === "Respond to a situation"
                //           ? 0
                //           : 0
                //       }
                //       Waiting={
                //         quest.SubCategory === "Answer Short Question"
                //           ? 0
                //           : quest.SubCategory === "Describe Image"
                //           ? 0
                //           : quest.SubCategory === "Re-tell Lecture"
                //           ? 0
                //           : quest.SubCategory === "Repeat Sentence"
                //           ? 0
                //           : quest.SubCategory === "Respond to a situation"
                //           ? 0
                //           : 0
                //       }
                // RecordingTime={ //Time of Recording The User Response
                //             quest.SubCategory === "Answer Short Question" ? 7
                //           : quest.SubCategory === "Re-tell Lecture" ? 40
                //           : quest.SubCategory === "Repeat Sentence" ? 15
                //           : quest.SubCategory === "Describe Image"  ? 40
                //           : quest.SubCategory === "Respond to a situation" ? 15
                //           : 40
                //       }


                      audio={
                        quest.SubCategory === "Describe Image" ||
                        quest.SubCategory === "Read Aloud"
                          ? null
                          : quest.SubCategory === "Repeat Sentence" ||
                            quest.SubCategory === "Answer Short Question" ||
                            quest.SubCategory === "Re-tell Lecture" ||
                            quest.SubCategory === "Respond to a situation"
                          ? quest_data.AudioObjects[0].audio_url ||
                            quest_data.AudioObjects[0].url
                          : Filler
                      }
                      MicAudioRef={audioRef}
                      setDisabledButton={setDisabledButton}
                      mediaRecorderRef={mediaRecorderRef}
                      setTranscriptLoading={setTranscriptLoading}
                      stopRecordingProcess={stopRecordingProcess}
                    />
                  )}
                </div>
              </div>
              {quest.SubCategory === "Read Aloud" && (
                <h5
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    userSelect: "none",
                  }}
                  onCopy={(e) => e.preventDefault()}
                >
                  {" "}
                  {formatText(quest_data.QuestionStatement)}
                </h5>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "45px",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#f2f3f7",
                    border: "none",
                    borderRadius: "4px",
                    padding: "10px 20px",
                    fontFamily: "Noto Sans",
                    fontWeight: 500,
                    cursor: "pointer",
                    
                  }}
                  onClick={handleExit}
                >
                  Save & Exit
                </button>
                <PurpleBtn onClick={handleNext} disabled={DisabledButton}>
                  Next
                </PurpleBtn>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
