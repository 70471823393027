import React, { useEffect, useRef, useState } from "react";
import { MediaRecorder } from "extendable-media-recorder";
import { setupWavEncoder } from "../../Speaking/wavEncoderSetup";

export default function RecordingComponent({
  callNext,
  clicked,
  setTempTranscript,
  step,
  transcript,
  setTranscript,
  Beginning,
  Waiting,
  RecordingTime,
  audio,
  MicAudioRef,
  setDisabledButton,
  setAudioUrl,
  mediaRecorderRef,
  setTranscriptLoading,
}) {
  const [timer, setTimer] = useState(Waiting);
  const [initTimer, setInitTimer] = useState(audio ? Beginning : 0); //Beginning in Time
  const [isRecording, setIsRecording] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const speechRecognitionRef = useRef(null);
  const recordingIntervalRef = useRef(null);
  const [isPlayingAudio, setIsPlayingAudio] = useState(audio ? true : false);
  const audioChunksRef = useRef([]);

  const playBeep = (frequency = 520, duration = 200, volume = 1) => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioCtx.createOscillator();
    const gainNode = audioCtx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    oscillator.type = "sine";
    oscillator.frequency.value = frequency;
    gainNode.gain.value = volume;

    oscillator.start();
    setTimeout(() => {
      oscillator.stop();
      audioCtx.close();
    }, duration);
  };

  useEffect(() => {
    stopRecording();
  }, [clicked]);

  useEffect(() => {
    setupWavEncoder();
  }, []);

  useEffect(() => {
    setTimer(Waiting);
    // setTranscript("");
    setIsRecording(false);
    // if (speechRecognitionRef.current) {
    //   speechRecognitionRef.current.stop();
    //   setCurrentTime(0);
    // }
    // if (mediaRecorderRef.current) {
    //   mediaRecorderRef.current.stop();
    // }
    setDisabledButton(true); // Disable "Next" button initially
  }, [step]);

  useEffect(() => {
    if (initTimer > 0) {
      const countdown = setTimeout(() => {
        setInitTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else if (audio) {
      MicAudioRef.current.src = audio;
      MicAudioRef.current.onloadedmetadata = () => {
        MicAudioRef.current.play();
        setIsPlayingAudio(true);
      };

      MicAudioRef.current.onended = () => {
        setIsPlayingAudio(false);
        setTimer(Waiting);
        // setTranscript("");
      };

      MicAudioRef.current.ontimeupdate = () => {
        setCurrentTime(MicAudioRef?.current?.currentTime);
      };
    } else {
      setTimer(Waiting);
    }
  }, [initTimer, audio, Waiting]);

  useEffect(() => {
    setCurrentTime(0);
    if (!isPlayingAudio && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else if (timer <= 0 && !isPlayingAudio) {
      playBeep(850, 200, 0.5);
      startRecordingIntro();
      setDisabledButton(false);
    }
  }, [timer, isPlayingAudio]);

  useEffect(() => {
    if (isRecording) {
      // setDisabledButton(true); // Disable "Next" button during recording
      recordingIntervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime < RecordingTime) {
            return prevTime + 0.1;
          } else {
            playBeep(700, 300, 0.3); // Beep when recording ends
            stopRecording(); // Stop the recording
            // setDisabledButton(false); // Enable "Next" button after recording
            callNext(); // Call the next step
            return prevTime;
          }
        });
      }, 100); // Update every 0.1 seconds
    } else {
      clearInterval(recordingIntervalRef.current);
    }
  }, [isRecording]);

  useEffect(() => {
    if (transcript !== "") {
      setTempTranscript(transcript);
      setTranscriptLoading(false);
    }
  }, [transcript]);

  const startRecordingIntro = async () => {
    if ("webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsRecording(true);
      };

      recognition.onresult = (event) => {
        let interim_transcript = "";
        setTranscriptLoading(true);
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            setTranscript(
              (prevTranscript) =>
                prevTranscript + event.results[i][0].transcript + " "
            );
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event.error);
      };

      recognition.onstop = () => {
        speechRecognitionRef.current = null;
        setIsRecording(false);
        setDisabledButton(false); // Enable "Next" button after recording stopped
      };

      recognition.start();
      speechRecognitionRef.current = recognition;
    } else {
      console.log("Speech recognition not supported in this browser.");
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioChunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/wav",
      });

      mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
        audioChunksRef.current.push(event.data);
      });

      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    setTempTranscript(transcript);
    if (!speechRecognitionRef.current && !mediaRecorderRef.current) {
      return;
    }

    if (speechRecognitionRef.current) {
      speechRecognitionRef.current.stop();
      speechRecognitionRef.current = null;
    }

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const file = new File([audioBlob], "audiofile.wav", {
          type: "audio/wav",
          lastModified: Date.now(),
        });
        setAudioUrl(file);
      };
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          backgroundColor: "rgba(153, 108, 254, 0.10)",
          maxWidth: "350px",
          borderRadius: "8px",
          padding: "15px",
          margin: "30px auto",
        }}
      >
        <h5
          style={{
            color: "#333",
            fontSize: "14px",
            paddingTop: "10px",
            textAlign: "center",
            margin: "0px",
          }}
        >
          Recorded Answer
        </h5>
        <p style={{ fontSize: "14px", padding: "0px" }}>
          {audio && initTimer > 0
            ? `Beginning in ${initTimer} sec`
            : isPlayingAudio
            ? `Playing Audio...`
            : timer > 0
            ? `Recording in ${timer} sec`
            : isRecording
            ? "Recording..."
            : "Done"}
        </p>
        <div>
          <audio ref={MicAudioRef}>
            Your browser does not support the audio element.
          </audio>
          <div style={{ width: "100%" }}>
            <input
              type="range"
              value={currentTime}
              min="0"
              // max={isPlayingAudio ? MicAudioRef?.current?.duration : RecordingTime}
              max={isPlayingAudio && MicAudioRef?.current?.duration ? MicAudioRef.current.duration : (RecordingTime || 40)}
              step="0.1"
              className="slider"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
