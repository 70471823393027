import React from "react";
import Navbar from "../components/Navbar/Navbar";
import PaymentSuccessPage from "../components/SideBar/PaymentSuccessPage";

export const PaymentSuccess = () => {
  return (
    <>
      {/* <Navbar /> */}
      <PaymentSuccessPage />
    </>
  );
};
