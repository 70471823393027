import React from "react";
import CancelIcon from "../../assets/images/carbon_close-filled.svg";
import { FlexDiv } from "../../assets/styles/style";
import {
  DeleteNoBtn,
  DeleteYesBtn,
  PopupHeader,
  PopupTitleText,
  PopupWhiteDiv,
} from "./style";

const SureToDeletePopup = ({ close, onDelete }) => {
  return (
    <>
      <div id="sure-to-delete">
        <PopupHeader>
          <FlexDiv style={{ width: "95%", justifyContent: "flex-end" }}>
            <img
              src={CancelIcon}
              alt=""
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => close(false)}
            />
          </FlexDiv>
        </PopupHeader>
        <PopupWhiteDiv>
          <PopupTitleText>Are you sure you want to?</PopupTitleText>
          <FlexDiv style={{ gap: "1.25rem" }}>
            <DeleteNoBtn onClick={() => close(false)}>No</DeleteNoBtn>
            <DeleteYesBtn
              onClick={() => {
                onDelete();
                // close(false);
              }}
            >
              Yes
            </DeleteYesBtn>
          </FlexDiv>
        </PopupWhiteDiv>
      </div>
    </>
  );
};

export default SureToDeletePopup;
