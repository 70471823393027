  import React, { createContext, useContext, useState, useEffect } from "react";
  import AuthInterceptor from "./AuthInterceptor";

  const AuthContext = createContext({});

  export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
      const userData = localStorage.getItem("userData");

      if (userData) {
        setIsAuthenticated(true);
        setUser(JSON.parse(userData));
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      AuthInterceptor(logout)
    }, []);

    const login = (userData) => {
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("pte-type", "pte academic");
      setIsAuthenticated(true);
    };

    const logout = () => {
      localStorage.removeItem("userData");
      // localStorage.removeItem("pte-type");
      setIsAuthenticated(false);
    };

    return (
      <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  };

  export const useAuth = () => useContext(AuthContext);
