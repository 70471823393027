export const Options = [
    {
        id: '1',
        text: 'All',
    },
    {
        id: '2',
        text: 'Known',
    },
    {
        id: '3',
        text: 'Unknown',
    },
]