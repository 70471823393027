export const Options = [
    {
        id: '1',
        text: 'Speaking',
    },
    {
        id: '2',
        text: 'Writing',
    },
    {
        id: '3',
        text: 'Reading',
    },
    {
        id: '4',
        text: 'Listening',
    },
    // {
    //     id: '5',
    //     text: 'PTE',
    // },
    // {
    //     id: '6',
    //     text: 'PTE-Core',
    // },
]